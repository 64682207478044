
.wrapper
    width: 100%
    flex: 1
    display: flex
    flex-direction: column
    padding: 7rem 3rem 3rem 3rem
    .whiteLabeledWrapper
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-between
        flex: 1
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        .headerTitle
            font-size: 3.6rem
            font-weight: 900
            color: #222
            line-height: 1.33
.appSwitcher
    background-color: #F2F2F7
    height: 40px
    border-radius: 30px
    border: 2px solid #E5E5EA
    display: flex
    flex-direction: row
    align-items: center
    width: fit-content
    margin: 0 40px
