.wrapper, .rtl
  width: 37rem
  border-radius: 5px
  border: solid 1px #d9d9d9
  // box-shadow: 34px 55px 69px 0 rgba(31, 4, 48, 0.1)
  background-color: #ffffff
  margin-bottom: 1rem
  display: flex
  flex-direction: column
  padding: 2.4rem
  .customerPhoneWrapper
    display: flex
    align-self: flex-start
    flex-direction: row
    justify-content: space-between !important
    padding: 0 16px
    position: relative
    left: 0
    bottom: 5px
    .contactWrapper
      display: flex
      justify-content: center
      align-self: center
      align-items: center
      width: 100%
      background-color: white
    .phoneWrapper
      display: flex
      align-self: center
      flex-direction: row
      .phone
        font-size: 1.6rem
        font-weight: 500
        color: #1a1a1a

  .separator
    margin-top: 2.4rem
  .brandAddressWrapper
    display: flex
    flex-direction: column
    padding-top: 3.2rem
    .brandAddressTitle
      font-size: 1.4rem
      font-weight: 900
      color: #1a1a1a
    .brandAddress
      font-size: 1.4rem
      color: #1a1a1a
  .brandTitle
    font-size: 1.8rem
    font-weight: 500
    color: #1a1a1a
  .brandWrapper
    display: flex
    flex-direction: row
    padding: 2.4rem 0
    .brand
      display: flex
      flex-direction: column
      align-items: center

      .brandInfo
        display: flex
        flex-direction: column
        padding: 0.2rem 2rem
        .title
          font-size: 1.6rem
          font-weight: 900
          color: #1a1a1a
        .address
          font-size: 14px
          color: #1a1a1a
      .brandAvatarWrapper
        border: 1px solid #e6e6e6
        z-index: 1
        width: 4.9rem
        height: 4.9rem
        border-radius: 3.2rem
        background-color: #f09440
        position: relative
        display: flex
        justify-content: center
        align-items: center
        font-size: 1.8rem
        .brandAvatarIcon
          border: 1px solid #e6e6e6
          border-radius: 3.2rem
          width: 100%
      // .contactWrapper
      //   display: flex
      //   justify-content: center
      //   align-self: center
      //   align-items: center
      //   width: 3.2rem
      //   border-radius: 2rem
      //   background-color: white
      //   position: relative
      //   top: 2.8rem
        // margin-top: 2.8rem
.rtl
  direction: rtl
  .brandWrapper
    .brand
      .brandInfo
        .address
          text-align: right
