.wrapper
    display: flex
    flex-direction: column
    align-items: center

    .body
        padding: 24px
        display: flex
        flex-direction: column
        .errorWrapper
            display: flex
            flex-direction: row
            align-items: center
            padding-top: 16px
            .alertIcon
                width: 24px
                height: 24px
                margin-right: 8px
            span
                color: #E40031
                font-size: 16px
                font-weight: 400
        .changeBannerBtn
            color: #FF9000
            font-size: 16px
            font-weight: 700
            background-color: transparent
            border: none
            outline: none
            margin-top: 18px
            display: flex !important
            justify-content: center !important
            .uploadIcon
                width: 24px
                height: 24px
                margin-right: 8px
        .imageWrapper
            display: flex
            justify-content: center
            align-items: center
            outline: dashed 1px #8E8E93
            height: 190px
            width: 343px
            border-radius: 8px
            background-color: #F9F9FB
            &.isDraggingOver
                outline-color: #f09440
            .image
                width: 100%
                height: 100%
                border-radius: 8px
                object-fit: cover
                object-position: center
            .content
                display: flex !important
                flex-direction: column
                align-items: center
                justify-content: center !important
                height: 100%
                width: 100%
                cursor: pointer
                margin-bottom: 0
                .description
                    margin-bottom: 12px
                    color: #333
                    font-size: 16px
                    font-weight: 400
                .bannerDescription
                    color: #8E8E93
                    font-size: 14px
                    font-weight: 400

    .footer
        padding: 24px
        width: 100%
        background-color: #F9F9FB
        display: flex
        justify-content: flex-end
        border-radius: 0 0 8px 8px
        .disabledAddBannerBtn, .addBannerBtn
            outline: none
            min-width: 137px
            display: flex
            height: 48px
            padding: 10px 24px
            justify-content: center
            align-items: center
            gap: 8px
            background-color: #F2F2F7
            border-radius: 4px
            color: #8E8E93
            font-size: 16px
            font-weight: 700
            .trashIcon
                width: 16px
                height: 16px
                margin-right: 8px
        .addBannerBtn
            border-radius: 4px
            background-color: #FF9000
            color: #FFF

    .header
        display: flex
        justify-content: space-between
        align-items: center
        flex-direction: row
        width: 100%
        padding: 16px 24px
        .title
            color: #333
            font-size: 20px
            font-weight: 700
        .closeIcon
            cursor: pointer
            width: 24px
            height: 24px
    &.rtl
        .body
            .changeBannerBtn
                .uploadIcon
                    margin-right: 0
                    margin-left: 8px
            .errorWrapper
                .alertIcon
                    margin-right: 0
                    margin-left: 8px
