@import ../../sass/colors

.wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-top: 4rem
  .title
    font-size: 2.5rem
  .paragraph
    font-size: 1.8rem
    text-align: center
  .btn
    background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%)
    border: none
    width: 13rem
    margin: 2rem 0
    height: 6rem
    font-size: 1.6rem
    outline: 0
    cursor: pointer
    border-radius: 4rem
    color: white
    transition: all .2s
    outline: none !important
  .btn:hover
    transform: translateY(-3px)
    box-shadow: $box-shadow-btn-hover
  .btn:active
    box-shadow: $box-shadow-btn-active
    transform: translateY(-1px)
.message
  display: block !important
  font-size: 14px
  text-align: center
.header
  display: block !important
  width: 100%
  color: #1C1C1E
  font-size: 20px
  text-align: center
  padding: 20px 20px
.imageContainer
  display: flex
  justify-content: center
  align-items: center
.buttonContainer
  display: flex
  justify-content: flex-end
  align-items: center
  border-top: solid 1px #F2F2F7
  padding: 20px 0px 0px
  .btn
    outline: none
    padding: 10px 20px
    margin-right: 10px
    border-radius: 4px
    font-size: 16px
    &:active,&:hover
      outline: none
    &.cancel
      background-color: #F2F2F7
      color: #000
    &.danger
      background-color: #EE5253
      color: #fff
.closeContainer
  outline: none
  align-self: flex-end
  font-size: 25px
  width: 32px
  height: 32px
  line-height: 32px
  border-radius: 16px
  &:active,&:hover , &:focus
    outline: none

.rtl
  direction: rtl
