.wrapper, .rtl
  width: 27rem
  display: flex
  border: solid 1px #d9d9d9
  background-color: #ffffff
  flex-direction: column
  padding-bottom: 3rem
  border-radius: .5rem
  height: 100%
  align-items: center
  overflow: scroll !important
  .rejectionReason
    border-radius: 5px
    border: solid 1px #db3030
    background-color: rgba(219, 48, 48, 0.1)
    display: flex
    flex-direction: row
    position: relative
    padding: 1.4rem !important
    top: 7.6rem
    margin: 0 0 1rem 0
    .reasonWrapper
      display: flex
      flex-direction: column
      margin-left: .8rem
      .reasonTitle
        font-size: 1.2rem
        font-weight: 900
        color: #db3030
      .reason
        font-size: 1.2rem
        font-weight: 500
        color: #db3030
  .orderTrackingHeader
    margin-bottom: 1rem
    width: 100%
    position: sticky
    top: 0
    z-index: 11
    background-color: white
    padding: 2.8rem
    .title
      font-size: 1.8rem
      font-weight: 500
      color: #1a1a1a
      width: 100%
  .progressItem
    width: 27rem
    display: flex
    // align-self: center
    flex-direction: column
    align-items: flex-start
    padding: 0 2.4rem
    .finlay
      display: flex
      justify-content: space-between
      height: 1.8rem
      position: absolute
      right: 2rem
    .link
      display: flex !important
      justify-content: space-between !important
      height: 1.8rem !important
    .img
      width: 2.4rem
      height: 2.4rem
    .dot
      transition: .2s all
      width: 1.8rem
      height: 1.8rem
      border-radius: 50%
      background-color: #a6a6a6
      display: flex !important
      &:active
        background-color: #ffffff
    .orderStateWrapper
      display: flex
      justify-content: center
      flex-direction: column
      width: 100%
      align-items: flex-start
      padding: 2.5rem 2rem
      .orderState
        font-size: 1.8rem
        font-weight: 700
        letter-spacing: 0.23px
        color: #1a1a1a
        display: flex !important
      .timeWrapper
        display: flex
        flex-direction: row
        align-items: center
        .time
          font-size: 1.4rem
          font-weight: 500
          width: 15rem
          letter-spacing: 0.23px
          color: #a6a6a6
        .collapse
          width: 1rem
          height: 1rem
        .country
          font-size: 1.4rem
          font-weight: 500
          letter-spacing: 0.23px
          color: #f09440
    .line, .lineForState, .acceptedLine
      transition: .2s all
      display: flex
      justify-content: center
      width: .4rem
      height: 8rem
      margin: .5rem 0 0 1rem
    &:last-child
      .line, .lineForState, .acceptedLine
        display: none
.wrapper::-webkit-scrollbar
  width: .3rem
  display: none
.wrapper::-webkit-scrollbar-thumb
  background-color: #d9d9d9
  border-radius: 2rem
.userName
  font-size: 1.2rem
  text-transform: uppercase
  text-align: right
  color: #ffffff
.rtl
  .progressItem
    .orderStateWrapper
      display: flex
      position: relative
      top: -1rem
      .orderState
        direction: rtl
    .lineForState
      margin: 0 1rem 0 0
