.closeWrapper
  right: 1.5rem
.closeWrapperRtl
  left: 1.5rem
.close
  margin: 0
  width: 1rem
  height: 1rem
.wrongCode, .rightCode, .pendingWrapper
  display: flex
  min-width: 28rem
  min-height: 6rem
  border-radius: 5px
  box-shadow: 0 12px 24px 0 rgba(0, 23, 59, 0.1)
  background-color: #db3030
  padding: 1.1rem 1.6rem
  align-items: center
  z-index: 4000

  img
    width: 3rem
    height: 3rem
    margin: 0 .5rem
  .content, .pendingTitle
    margin: 0 2rem
    font-size: 1.4rem
    font-weight: 900
    letter-spacing: 0.32px
    color: #ffffff
  .pendingTitle
    color: #1a1a1a
.rightCode
  background-color: #38c172
.pendingWrapper
  background-color: #f7cc49
.rtl
  direction: rtl !important
  .wrongCode, .rightCode
    border-left: 0
  *
    direction: rtl !important
    text-align: right
