.wrapper
  display: flex
  width: 100%
  justify-content: space-between
  align-items: flex-start
  padding-bottom: 4.8rem
  border-left: 1.5px solid #9b9b9b
  position: relative
  &:not(.rtl)
    .dot
      left: -1.6rem

  .dot
    background: white
    position: absolute
    top: 0
    height: 3.2rem !important
    min-width: 3.2rem !important
    width: 3.2rem !important
    border-radius: 50%
    display: inline-block
  .info
    display: flex
    flex-direction: column
    width: 100%
    margin: 0 4rem
    .userWrapper
      display: flex
      width: 100%
      justify-content: space-between
      .user
        display: flex
        flex: 1
        .userImage
          min-width: 3.2rem !important
          width: 3.2rem !important
          height: 3.2rem !important
          min-height: 3.2rem !important
          border-radius: 50%
        .textWrapper
          margin: 0 1.1rem
          font-size: 1.8rem
          font-weight: 500
          color: #222
      .date
        font-size: 1.4rem
        font-weight: 500
        color: #9b9b9b
    .commentText
      color: #222
      margin: 1.1rem 0 0
      font-size: 1.6rem
.wrapper:last-child
  border-left: 0

.wrapper.rtl
  border-right: 1.5px solid #9b9b9b
  border-left: 0
  .dot
    right: -1.6rem
    left: unset

