.main
  display: flex
  align-items: center
  justify-content: center
.wrapper, .rtl
  width: 124rem
  height: 95%
  border-radius: 2rem
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
  border: solid 1px #979797
  background-color: #ffffff
  display: flex
  flex-direction: column
  outline: none
  .contentWrapper
    display: flex
    flex-direction: column
    padding: 2.4rem 3.2rem
    width: 100%
    height: 76rem
    flex-direction: row
    overflow: scroll
    overflow-y: none !important
    .separator
      margin: 0 1.4rem
  .contentWrapper::-webkit-scrollbar
    width: .3rem
    display: none
  .contentWrapper::-webkit-scrollbar-thumb
    background-color: #d9d9d9
    border-radius: 2rem
  .headerWrapper
    position: relative
    z-index: 11
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%
    height: 9.4rem
    border-radius: 2rem 2rem 0 0
    padding: 3.2rem
    box-shadow: 1px 3px 14px 0 rgba(31, 4, 48, 0.1)
    background-color: #ffffff
    .headerTitle
      font-size: 2.2rem
      font-weight: 500
      color: #2d2d2d
    .closeIcon
      width: 2.2rem
      height: 2.2rem
      cursor: pointer
.rtl
  direction: rtl
