@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-Oblique.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-Oblique.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-Oblique.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-Oblique.svg#AvenirLTStd-Oblique') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-Book.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-Book.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-Book.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-Medium.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-Medium.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-Medium.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-HeavyOblique.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-HeavyOblique.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-HeavyOblique.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-HeavyOblique.svg#AvenirLTStd-HeavyOblique') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-MediumOblique.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-MediumOblique.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-MediumOblique.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-MediumOblique.svg#AvenirLTStd-MediumOblique') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-Roman.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-Roman.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-Roman.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-BlackOblique.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-BlackOblique.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-BlackOblique.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-BlackOblique.svg#AvenirLTStd-BlackOblique') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-Heavy.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-Heavy.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-Heavy.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-Black.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-Black.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-Black.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-LightOblique.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-LightOblique.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-LightOblique.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-LightOblique.svg#AvenirLTStd-LightOblique') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-Light.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-Light.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-Light.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('./assets/fonts/AvenirLTStd-BookOblique.woff2') format('woff2'),
        url('./assets/fonts/AvenirLTStd-BookOblique.woff') format('woff'),
        url('./assets/fonts/AvenirLTStd-BookOblique.ttf') format('truetype'),
        url('./assets/fonts/AvenirLTStd-BookOblique.svg#AvenirLTStd-BookOblique') format('svg');
    font-weight: 100;
    font-style: italic;
}

