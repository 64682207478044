.live-updates {
  .ant-popover-arrow {
    display: none !important;
  }

  .ant-popover-inner {
    cursor: pointer;
    border-radius: 50px;
    background-color: #1c1c1e;
    color: white;
    margin-top: 5rem;
  }
  .ant-popover-inner-content {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;