.wrapper
    display: flex
    flex-direction: column
    .titleAndBulletsWrapper
        display: flex
        flex-direction: row
        margin-bottom: 22px
        .title
            font-family: Avenir
            font-size: 16px
            font-weight: 500
            font-stretch: normal
            font-style: normal
            line-height: normal
            letter-spacing: normal
            color: #1a1a1a
            margin-right: 24px
        .bullets
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            justify-items: center
            font-size: 11px
            color: #1a1a1a
