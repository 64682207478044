.range-item:hover,
.range-item:active,
.range-item:visited,
.range-item:focus {
  background-color: white !important;
}

.range-number-input:hover .ant-input-number-handler-wrap {
  display: none !important;
}
.range-number-input {
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  input {
    color: #1c1c1e;
    font-weight: bold;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    display: none !important;
  }
  input[type="number"] {
    -moz-appearance: textfield !important;
  }
}

.range-number-input:hover,
.range-number-input:focus-visible,
.range-number-input:focus {
  border: 0 !important;
  border-color: none !important;
  outline: none !important;
  border-right-width: 0 !important;
  box-shadow: none !important;
}

.range-number-input .ant-input-number-input-wrap:focus-visible {
  border: none !important;
  outline: none !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;