
.navbarWrapper, .navbar
    height: 100vh
    // overflow: auto
    width: 248px
    background-color: #1c1c1e !important

    z-index: 102
    display: flex
    position: fixed
    // top: 0
    flex-direction: column
    justify-content: flex-start
    align-items: center
    box-shadow: 0 12px 24px rgba(0, 23, 59, 0.1)
    transition: all .2s

.logoWrapper
    height: 72px !important
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    padding: 24px
    .largeLogoWrapper
        width: 100% !important
        display: flex
        justify-content: center
        align-items: center
        .largeIcon
            width: 83.6px
            height: 32px
            flex-grow: 0
            object-fit: contain

.navbar
    width: 88px
.itemTitle
    color: #aeaeb2 !important
    margin-left: 62px
    margin-top: 10px
.collapseItem,
.activeCollapseItem
    font-size: 16px
    color: #aeaeb2 !important
    text-decoration: none !important
    padding-top: 10px
    padding-bottom: 11px
    &:hover
        font-weight: bold
        color: #ffa351 !important
.activeCollapseItem
    font-weight: bold
    color: #ffa351 !important
.navbarMain
    overflow: auto
    width: 100%
    overflow-x: hidden
    overflow-y: scroll
    .navLinkCollapsed,
    .activeNavLink,
    .activeNavLinkCollapsed,
    .navLink
        cursor: pointer
        height: 56px
        padding: 16px 38px
        text-decoration: none !important
        border-radius: 0 4.125rem 4.125rem 0
        display: flex
        align-items: center
        .feedbackWrapper
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            width: 100%
            .feedbackTitle
                font-size: 16px
                color: #aeaeb2
                margin-left: 10px
                text-decoration: none
        .navTitle
            font-size: 16px
            color: #aeaeb2
            margin-left: 10px
            text-decoration: none
    .navLink
        display: flex
        justify-content: center
        padding: 0 !important
    .activeNavLink
        background-color: rgba(255, 163, 81, 0.12)
        border-radius: 0 4.125rem 4.125rem 0
        height: 56px
        display: flex
        align-items: end
        // margin: 
        margin-right: 12px
        &.rtl
            background-color: rgba(255, 163, 81, 0.12)
            border-radius: 66px 0 0 66px
            margin-right: 0
            margin-left: 12px
        .feedbackWrapper
            .feedbackTitle
                color: #ffa351
                text-decoration: none
                font-weight: bold
        .navTitle
            color: #ffa351
            text-decoration: none

            font-weight: bold
    .activeNavLinkCollapsed
        display: flex
        height: 56px
        padding: 16px !important
        margin: 0 16px
        border-radius: 4px
        background-color: rgba(255, 163, 81, 0.12)
        justify-content: center
    .rtl
        .navTitle
            margin-right: 10px
            margin-left: 0

.navbarMain::-webkit-scrollbar
    display: none
