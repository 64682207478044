.cardWrapper
    height: 250px
    max-height: auto
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: calc(100% / 5)
    box-shadow: -1px 0px 0px 0px #F2F2F7 inset
    text-align: center
    .rank
        background: #F2F2F7
        color: #8E8E93
        width: 30px
        height: 30px
        padding: 10px
        display: flex
        justify-content: center
        align-items: center
        font-size: 1.3rem
    .price
        font-size: 1.35rem
        color: #8E8E93
        margin-top: 1rem
    .name
        font-weight: 500
        font-size: 1.5rem
        margin-top: 1.5rem
    .count
        font-size: 1.35rem
        margin-top: 0.5rem
    .image
        width: 56
        height: 56
        margin: 8px 0px
        border-radius: 50%
        margin-top: 2rem
        .img
            width: 100%
            height: 100%
