.genericInputDefaults
  border-radius: 4px
  border: solid 1px #e3e3e3
  background-color: #ffffff
  color: #2d2d2d
  padding: 2rem
  font-size: 1.6rem

  &::placeholder
    color: #9b9b9b
