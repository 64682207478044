.wrapper
    display: flex
    flex-direction: column
    justify-items: center
    align-items: center
    width: 100%
    box-sizing: border-box
    .iconWrapper
        display: flex
        flex-direction: column
        justify-items: center
        align-items: center
        justify-content: center
        background-color: #F2F2F7
        width: 80px
        height: 80px
        border-radius: 50%
        margin-bottom: 24px
        .downloadIcon
            width: 30px
            align-self: center
            justify-self: center
    .closeButtonWrapper
        margin-bottom: 24px
        align-self: flex-end
        .closeButton
            border-radius: 50%
            width: 32px
            height: 32px
            box-sizing: border-box
            outline: none
    .modalTitle
        font-weight: bold
        font-size: 20px
        line-height: 27px
        text-align: center
    .modalSubtitle
        font-style: normal
        font-weight: normal
        font-size: 16px
        line-height: 22px
        margin-bottom: 24px
    .timeFiltersWrapper
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-between
        margin-bottom: 24px
        .singleFilterWrapper
            display: flex
            flex-direction: column
    .timeFilterErrMsg
        width: 100%
        margin-bottom: 24px
    .separator
        width: 100%
        height: 1px
        background-color: #F2F2F7
        margin-bottom: 16px
