.main
    width: 100% !important
    height: auto
    margin-bottom: 23px
    .headerWrapper
        padding: 20px 24px
        width: 100% !important
        display: flex
        background-color: #fff
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        align-content: flex-start
        .noDataWrapper
            display: flex
            flex-direction: column
            width: 100%
            height: 100%
            justify-content: center
            align-items: center
            margin-top: 35px
            .noDataInfoIcon
                width: 87px
                height: 87px
            .noDataDesc
                width: 386px
                height: 44px
                font-size: 16px
                font-weight: 400
                line-height: 22px
                text-align: center
                color: #000000
                margin-top: 17px
                margin-bottom: 15px
        .headerTitleWrapper
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            .title
                display: flex
                flex-direction: row
                font-size: 24px
                font-weight: 700
                .infoIcon
                    width: 20px
                    height: 20px
                    margin-left: 12px
                    .icon
                        width: 100%
                        height: 100%
            .seeMoreButton
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                padding: 10px 12px
                background-color: white
                border: none
                font-weight: bold
                height: 40px
                &:hover
                    background-color: #f2f2f7
                    transition: 0.2s
                    border: none
                &:focus
                    outline: none
                span
                    padding-left: 5px
        .numbersWrapper
            display: flex
            flex-direction: row
            justify-content: space-between
            width: 100%
            .customersCountWrapper
                display: flex
                flex-direction: column
                width: 100%
                .legendWrapper
                    display: flex
                    flex-direction: row
                    align-items: center
                    margin-top: 26px
                    .legend
                        width: 12px
                        height: 12px
                        border-radius: 50%
                        margin-right: 8px
                .customersCount
                    font-size: 20px
                    font-weight: 700
                    margin-top: 12px
                .last30DaysCompared
                    display: flex
                    flex-direction: row
                    align-items: center
                    margin-top: 8px
                    font-weight: 400
                    font-size: 14px
                    .arrowIcon
                        height: 16px
                        margin-left: 10px
        .percentBar
            width: 100%
            height: 27.2px
            background-color: #FFD1A8
            border-radius: 4px
            margin-top: 24px
            .innerPercentBar
                height: 27.2px
                background-color: #FFA351
                border-top-left-radius: 4px
                border-bottom-left-radius: 4px
    .panelContentWrapper
        border-bottom: solid 0.1rem #e5e5ea
        display: flex
        flex-direction: column
        width: 100%
        padding: 24px
        .filtersWrapper
            display: flex
            flex-direction: row
            min-width: 300px
            width: auto
            align-self: flex-end
            justify-content: space-between
            align-items: center
        .barChartWrapper
            padding: 18px 21px
.rtl
    .headerWrapper
        .headerTitleWrapper
            .seeMoreButton
                span
                    padding-right: 5px
                    padding-left: 0
            .title
                .infoIcon
                    margin-left: 0
                    margin-right: 12px
        .numbersWrapper
            .customersCountWrapper
                .last30DaysCompared
                    .arrowIcon
                        margin-left: 0
                        margin-right: 10px
                .legendWrapper
                    .legend
                        margin-right: 0
                        margin-left: 8px
