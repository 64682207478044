.wrapper
    width: 95%
    padding: 7rem 3rem 3rem 3rem
    min-height: 100vh
    .heading
        display: flex
        justify-content: flex-start
        margin-bottom: 3rem
        .headingText
            font-weight: bold
            font-size: 2.6rem
    .tableWrapper
        width: 100%
        display: flex
        flex-direction: column
        justify-content: center
        background: #FFFFFF
        border: 1px solid #E5E5EA
        box-sizing: border-box
        border-radius: 4px
        margin-top: 3rem
        .tableHead
            width: 100%
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            padding: 12px 24px 12px 24px
            .title
                font-size: 2rem
                display: flex
                align-items: center
                height: 100%
        .searchAndActions
            display: flex
            justify-content: flex-end
            align-items: flex-start
            width: 70%
            .seach
                margin-right: 1rem
                margin-left: 1rem
                height: 55px
        .filtersWrapper
            display: flex
            flex-direction: row
            justify-content: flex-end
            width: 100%
        .tableBody
.customerCellWrapper
    .customerImg
        height: 32px
        width: 32px
        border-radius: 50%
