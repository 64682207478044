@import ../../sass/colors

.content div:not(.confirmBtn, .confirmBtn div)
  border: none
.body
  padding: 7rem 0 2rem !important
  border-top: 6px solid #FF0606 !important
  outline: 0 !important
  box-shadow: none !important
.wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  .title
    font-size: 2.2rem
    margin: 2rem 0 0
  .paragraph
    margin: 0
    color: #898989
    font-size: 1.6rem
    display: flex
    align-items: center
    span
      margin: 0 .5rem
  .logo
    width: 5rem
    height: 5rem
  .btnWrapper
    align-self: flex-end
    margin: 3rem 1rem 0
    display: flex
    align-items: center
    .confirmBtn,.cancel, .cancelDisabled, .confirmBtnDisabled
      margin: 0 .5rem
      background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%)
      border: none
      width: 11.5rem
      height: 4.5rem
      font-size: 1.4rem
      outline: 0
      padding: 0
      cursor: pointer
      border-radius: 4rem
      color: white
      transition: all .2s
      outline: none !important
    .confirmBtnDisabled
      background-color: #999999 !important
      background-image: none
      cursor: not-allowed
    .cancelDisabled
      width: 7rem
      cursor: not-allowed
      border: 1px solid #999999
      color: #999999
      background: white
    .cancel
      width: 7rem
      border: 1px solid #FDBB11
      color: #FDBB11
      background: white
    .confirmBtn:hover,.cancel:hover
      transform: translateY(-3px)
      box-shadow: $box-shadow-btn-hover
    .confirmBtn:active,.cancel:active
      transform: translateY(-1px)
  &.rtl
    .btnWrapper
      align-self: flex-start
