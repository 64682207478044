.numbersAndFiltersContainer
    display: flex
    justify-content: space-between
.loadingWrapper
    width: 100%
    hight: 100%
    padding: 5rem 1rem
    display: flex
    justify-content: center
    align-items: center
.branchFilterWrapper
    margin: 0 1rem

.legendsContainer
    width: 100%
    height: 25px
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    font-size: 14px
    margin-top: 17px
    .legendWrapepr
        display: flex
        flex-direction: row
        margin-right: 5px
        margin-left: 5px
        justify-content: center
        align-items: center
