@import ../../../../sass/colors

.wrapper
  width: 95%
  display: flex
  align-items: center
  flex: 1
  justify-content: space-between
  .logo
    width: 48rem
    height: 45rem
  .column
    display: flex
    width: 40%
    flex-direction: column
    align-items: flex-start
    .subHeader
      color: #cfc3c3
      font-size: 1.6rem
      margin: 0 0 6rem
    .header
      font-size: 3.6rem
      font-weight: 900
      color: #222
      line-height: 1.33
    .form
      width: 100%
      .separator
        margin: 3rem 0
    .autoSave
      margin: 1rem 0
      font-size: 1.4rem
      color: #9B9B9B
      font-weight: normal
    .btn
      margin: 0
      border-radius: 4px
      background: #ffa351
      font-size: 1.4rem
      width: 10rem
      border: 0
      outline: 0 !important
      color: white
      height: 4.5rem
      font-weight: 900
    .btn:hover
      transform: translateY(-3px)
      box-shadow: $box-shadow-btn-hover !important
    .btn:active
      transform: translateY(-1px)
