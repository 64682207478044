.main
  width: 100% !important
  padding: 0 3rem
  cursor: pointer
  &:nth-child(even)
    background-color: #f2f2f2
  &:nth-child(odd)
    background-color: #ffffff
  .wrapper
    display: flex
    width: 100%
    flex-direction: row
    height: 7.2rem
    padding: 0 3rem
    align-items: center
    margin: .2rem 0
    overflow: hidden

    .separator
      margin: 0 5rem
    .time, .name, .orderCode, .price, .orderState
      flex: 1
      font-size: 1.6rem
      font-weight: 500
      font-stretch: normal
      font-style: normal
      line-height: normal
      letter-spacing: 0.7px
      color: #4d4d4d
    .time
      flex: 0.9 !important
    .orderSucceed, .orderFailed
      font-size: 1.4rem
      flex: 1 !important
      font-weight: 500
      color: #38c172
    .paymentMethod
      flex: 1 !important
      font-size: 14px
      font-weight: bold

    .orderFailed
      color: #db3030
    .price
      flex: 1 !important
      font-size: 1.4rem
      font-weight: 900
      color: #1a1a1a
    .brandWrapper
      display: flex
      flex-direction: column
      flex: 1.3 !important
      .brandName
        font-size: 1.4rem
        font-weight: 900
        color: #1a1a1a
      .branchName
        font-size: 1.4rem
        color: #1a1a1a
    .name
      flex: 1.3 !important
      color: #1a1a1a
    .orderCode
      flex: 0.6 !important
      color: #1a1a1a
    .orderType
      flex: 0.7 !important

      .pickupOrder, .deliveryOrder, .driveThru
        width: 4rem
        display: flex
        align-items: center
        justify-content: center
        height: 4rem
        border-radius: 2rem
        background-color: rgba(65, 18, 108, 0.2)
        .icon
          width: 1.6rem
          height: 1.6rem
      .deliveryOrder
        background-color: rgba(240, 148, 64, 0.2)
      .driveThru
        background-color: #E6FFEE
