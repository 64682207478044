@import ./sass/colors

.App
  background-color: white
  min-height: 100vh
  font-family: Avenir, 'Avenir LT Std','Cairo','Roboto', sans-serif
  font-weight: 400

.rtl
  direction: rtl
  text-align: right
  span
    text-align: right
.mainSection
  display: flex
.secondarySection
  display: flex
  width: 100%
  position: relative
  flex-direction: column
  background: #fafafa
  padding-top: 7rem
  align-items: center

.navBar
  min-height: 100vh
  padding: 5rem 0
  width: 88px
