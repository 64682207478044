@import ../../../sass/colors

.cardWrapper
  display: flex
  flex-direction: column
  padding: 1rem
  border-bottom: 1px solid #E3E3E3
  .date
    width: 100%
    color: #9b9b9b
    font-size: 1.4rem
    display: flex
    align-items: center
    .text
      margin: 0 1rem
    .logo
      margin: 0
      width: 1.7rem
      height: 1.7rem

  .statsWrapper
    display: flex
    align-items: center
    justify-content: space-between
    flex-wrap: wrap
    margin: 1.5rem 0 0 0
    .imgWrapper
      width: 65%
      display: flex
      align-items: center
      .img
        width: 3.5rem
        height: 3.5rem
        border-radius: 50%
      .imgName
        color: #2D2D2D
        margin: 0 1rem
        font-size: 1.4rem
    .p_status,.b_status
      .statusLabel
        color: #9B9B9B
        margin: 0
        font-size: 1.2rem
      .statusValue
        margin: 0
        font-weight: 500
        color: #2D2D2D
        font-size: 1.4rem
    .p_status
      width: 20%
    .b_status
      margin: 1rem 0 0 0
      width: 100%
