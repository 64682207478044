.modal
  display: flex
  justify-content: center
  align-items: center
.wrapper, .rtl
  width: 50.8rem
  border-radius: 1rem
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
  background-color: #ffffff
  outline: 0
  display: flex
  flex-direction: column
  .secondWrapper
    display: flex
    height: 40rem
    flex-direction: column
    overflow: scroll
    overflow-x: hidden
    .zonesWrapper
      display: flex
      flex-direction: column
      padding: .5rem 0
      .zoneWrapper
        display: flex
        flex-direction: column
        border-bottom: 1px solid #e6e6e6
        .city
          font-size: 1.6rem
          color: #000000
          padding: 0 3.2rem
        .area
          padding: 0 3.2rem
          font-size: 1.8rem
          font-weight: 900
          color: #000000
          padding-bottom: 1.6rem
  .secondWrapper::-webkit-scrollbar
    width: 1.5rem
  .secondWrapper::-webkit-scrollbar-thumb
    background-color: #d9d9d9
    border-radius: 2rem

  .title
    padding: 3.2rem
    font-size: 2.2rem
    font-weight: 500
    color: #2d2d2d
  .btnWrapper
    margin-top: 4.8rem
    width: 50.8rem
    height: 8rem
    border-radius: 1rem 0 1rem 1rem
    background-color: #f2f2f2
    display: flex
    align-items: center
    justify-content: flex-end
    padding: 0 2.4rem
    .cancel
      width: 14.4rem
      height: 4.8rem
      background-color: #f2f2f2
      border-radius: 4px
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      outline: 0
      color: #ee8728
      border: solid 1px #ee8728
.rtl
  direction: rtl
  .secondWrapper
    .zonesWrapper
      .zoneWrapper
