.updateNotifierWrapper
  padding: 2.4rem
  width: 38.6rem
  border-radius: 10px
  box-shadow: 0 20px 43px -12px rgba(126, 91, 1, 0.25)
  background-color: #ffffff
  color: #222222
  font-size: 1.8rem
  position: fixed
  bottom: 2.4rem
  left: 2.4rem
  z-index: 20000

  .updateFoundTitle
    margin-top: 1rem
    font-weight: 900
    line-height: 1.61

  .updateFoundDescription
    margin-bottom: 1.6rem

.updateNotifierWrapper.rtl
  left: unset
  right: 2.4rem
