
.wrapper
  display: flex
  flex-direction: column
  padding: 7rem 3rem 3rem 3rem
  .header
    display: flex
    flex-direction: column
    p
      font-size: 1.6rem
  .btnWrapper
    display: flex
    flex-direction: row
    justify-content: space-between
    .btn
      font-size: 1.4rem
      outline: none
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      color: #ffffff
      width: 18rem
      height: 5.2rem
      border-radius: .5rem
      background-color: #f09440
    .branchesCount
      display: flex !important
      align-items: flex-end !important
      font-size: 2.5rem
      font-weight: 700
      color: #000000
  .main
    width: 77rem
    border-radius: 1rem
    border: solid 1px #bfbfbf
    background-color: #fefefe
    margin: 10px auto
