@import ../../../sass/colors

.cardWrapper
  display: flex
  flex-direction: column
  padding: 1rem
  border-bottom: 1px solid #E3E3E3
  .date
    color: #9b9b9b
    width: 100%
    font-size: 1.4rem
    display: flex
    align-items: center
    .text
      margin: 0 1rem
    .logo
      width: 1.7rem
      height: 1.7rem
  .compensation
    font-size: 1.2rem
    font-weight: 900
    letter-spacing: .4px
    color: white
    background: #fdbb11
    padding: .2rem 0
    width: 10.5rem
    margin: 1rem 0
    text-align: center
    border-radius: 40px
  .statsWrapper
    display: flex
    align-items: center
    flex-wrap: wrap
    margin: 0
    .status,.branchStatus,.cashierStatus
      width: 33%
      .statusLabel
        color: #9B9B9B
        margin: 0
        font-size: 1.2rem
      .statusValue
        margin: 0
        font-size: 1.4rem
        font-weight: 500
        color: #2D2D2D
    .branchStatus,.cashierStatus
      width: 100%
      margin: .5rem 0 0

