.numbersAndFiltersContainer
    display: flex
    justify-content: space-between
    .totalOrdersWrapper
        display: flex
        flex-direction: column
        margin-bottom: 3.6rem
        padding: 0 .5rem
        .totalOrdersMain
            display: flex
            flex-direction: row
            align-items: center
            .totalOrdersTitle
                font-size: 2.4rem
                font-weight: bold
            .infoIcon
                margin: 0 .4rem
                width: 2rem
                height: 2rem
                transform: rotate(180deg)
                cursor: pointer
        .totalOrdersSubtitle
            color: #8e8e93
            font-size: 14px
.loadingWrapper
    width: 100%
    hight: 100%
    padding: 5rem 1rem
    display: flex
    justify-content: center
    align-items: center

.branchMultiSelect
    margin: 0 1rem

.legendsContainer
    width: 100%
    height: 25px
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    font-size: 14px
    margin-top: 17px
    .legendWrapepr
        display: flex
        flex-direction: row
        margin-right: 5px
        margin-left: 5px
        justify-content: center
        align-items: center
