@import "~antd/dist/antd.less";

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  width: 100%;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important;
}
.ant-tabs-tab {
  padding: 0 !important;
  width: 100%;
  justify-content: center;
}
.ant-tabs-tab:hover {
  color: #ffbd7a;
  background-color: #fff4ea;
}
.ant-tabs-tab:active {
  color: #ffbd7a;
  background-color: #fff4ea;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffa351;
  height: 100%;
  background-color: #fff4ea;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  display: none;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;