.isoCodeContainer
  font-size: 16px
  margin: 0 4px
.getIconContainer
  font-size: 18px
  margin-right: 16px
.flag
  font-size: 18px
.container
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
