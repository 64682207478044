.wrapper
    display: flex
    justify-content: flex-start
    align-items: center
    .image
        display: flex
        align-items: center
        justify-content: center
        width: 32px
        height: 32px
        border-radius: 50px
        box-shadow: 0px 0px 1px 1px #FFF4EA
        margin: 0px 8px
        background-color: #F2F2F7
        .customerName
            font-size: 14px
            text-transform: capitalize
            font-weight: 700
        .avatar
            width: 31px
            height: 31px
            border-radius: 50px

    .subTitle
        font-size: 12px
        font-weight: 400
        color: #8E8E93
