@import ../../../sass/colors

.dropDownToggle
  padding: 0 1.6rem !important
  height: 6rem

.wrapper
  width: 100%
  padding: 3rem 2.5rem
  display: flex
  .ddPart
    .label
      display: flex
      font-size: 1.6rem
      font-weight: 900
      color: #2D2D2D
      margin: 1rem 0
    .categoriesAndSeparator
      display: flex
      align-items: center

.dropDownItem
  display: flex
  align-items: center
  margin: 1.5rem 0
  *
    margin: 0
  img
    width: 3.3rem
    height: 3.3rem
    border-radius: 50%
  span
    margin: 0 .7rem
    font-weight: 500
    color: #2D2D2D
    font-size: 1.4rem
  p
    font-style: italic
    font-size: 1.4rem
    font-weight: 400
    color: #9B9B9B

.cats
  width: 36rem

.tagsWrapper
  display: flex
  flex-direction: column
  flex: 1
  .tagsTitle
    text-transform: uppercase
    font-weight: 900
    letter-spacing: 0.5px
    color: #9b9b9b
    font-size: 1.6rem
    padding: 0 2rem
    display: flex
    margin-bottom: 1rem
  .tagsCollection
    display: flex
    flex-direction: column
    .tag
      cursor: pointer
      display: flex
      min-width: 33%
      margin: .6rem 1.5rem
      align-items: center
      font-size: 1.4rem
      flex: 1
      .dot
        font-size: 1.8rem
        margin: 0 .5rem
      .text
        margin: 0
      &.positiveTag .dot
        color: #38c172
      &.negativeTag .dot
        color: #db3030
      &:hover .text
        text-decoration: underline
      &.active .text, .dot
        color: #fdae11

.allTagsWrapper
  flex: 1
  display: flex
  margin: 0 3rem

.catsAndTagsSeparator
  background: #d8d8d8
  align-self: center
  margin: 0 1.5rem
  height: 1px
  width: 3.7rem
