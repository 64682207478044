.ant-black-text-button:hover {
  background-color: #f2f2f7 !important;
  padding: 18px 24px;
}

.ant-black-text-button {
  padding: 10px 12px !important;
  margin: 0px 0px !important;
  display: flex;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;