#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background-color: #1c1c1e;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 3.2rem;
}

.ant-layout.ant-layout-has-sider {
  background-color: #1c1c1e;
}

.ant-layout-sider-children {
  background-color: #1c1c1e;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #1c1c1e;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #ffa351 !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 163, 81, 0.12);
  padding: 1.156rem 2rem 1.156rem 4rem;
  border-radius: 0 4.125rem 4.125rem 0;
  width: 95%;
  height: 56px;
}

.ant-menu.ant-menu-inline-collapsed:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: rgba(255, 163, 81, 0.12);
  padding: 1.156rem 2rem 1.156rem 4rem;
  border-radius: 4px !important;
  width: 95%;
  height: 56px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.ant-menu-dark .ant-menu-item:hover {
  padding: 1.156rem 2rem 1.156rem 4rem;
  border-radius: 0 4.125rem 4.125rem 0;
  width: 95%;
  height: 56px;
}

// .ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
// .ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
//   font-size: 16px;
//   color: #aeaeb2;
// }

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  height: 56px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item {
  padding: 0 32px !important;
}

// .ant-menu-dark .ant-menu-item:hover,
// .ant-menu-dark .ant-menu-item-active,
// .ant-menu-dark .ant-menu-submenu-active,
// .ant-menu-dark .ant-menu-submenu-open,
// .ant-menu-dark .ant-menu-submenu-selected,
// .ant-menu-dark .ant-menu-submenu-title:hover {
//   font-size: 1.6rem;
//   // font-weight: bold;
//   color: #ffa351;
// }

// .ant-menu-dark .ant-menu-item:active,
// .ant-menu-dark .ant-menu-item-active,
// .ant-menu-dark .ant-menu-submenu-active,
// .ant-menu-dark .ant-menu-submenu-open,
// .ant-menu-dark .ant-menu-submenu-selected,
// .ant-menu-dark .ant-menu-submenu-title:active {
//   font-size: 1.6rem;
//   font-weight: bold;
//   color: #ffa351 !important;
// }

// .ant-menu-dark .ant-menu-item-selected {
//   font-size: 1.6rem;
//   font-weight: bold;
//   color: #ffa351 !important;
// }

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark
  .ant-menu-sub
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding: 0.5rem !important;
}
.ant-layout-sider .ant-layout-sider-dark .ant-layout-sider-collapsed {
  width: 100%;
}
.ant-layout-sider {
  height: 100vh;
  background: #1c1c1e !important;
  flex: 0 !important;
  max-width: none !important;
  min-width: auto !important;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.12) !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #1c1c1e !important;
}

.ant-menu-inline-collapsed .ant-menu-item:hover {
  border-radius: 4px;
  height: 56px;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  padding: 0 1rem !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .ant-menu-item-icon
  + span,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon
  + span,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon
  + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon
  + span,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon
  + span,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon
  + span {
  display: none !important;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  width: 20px;
  min-width: 20px;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
}
.sidebar-divider {
  background-color: rgba(255, 255, 255, 0.12) !important;
  margin: 0 !important;
}

.home-bar {
}

// .ant-menu-item .ant-menu-item-active .home-bar{
//   padding-right: 16px !important;

// }

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin: 0;
}

.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  width: 100%;
}
.ac-side-bar-collapse {
  background-color: #fff;
}
.active-ant-collapse-header > .ant-collapse-header {
  background-color: rgba(255, 163, 81, 0.12);
  border-radius: 0 66px 66px 0 !important;
  margin-right: 16px !important;
  margin-bottom: 10px !important;
}
.active-rtl-ant-collapse-header
  > .ant-collapse-content
  > .ant-collapse-content-box {
  display: flex;
  flex-direction: column;
  padding-left: 0 !important;
  padding-bottom: 11px !important;
}
.active-rtl-ant-collapse-header > .ant-collapse-header {
  background-color: rgba(255, 163, 81, 0.12);
  border-radius: 66px 0 0 66px !important;
  margin-right: 0 !important;
  margin-bottom: 10px !important;
  margin-left: 16px !important;
}
.active-rtl-ant-collapse-header > .ant-collapse-header .ant-collapse-arrow svg {
  color: #ffa351 !important;
  width: 16px !important;
  height: 16px !important;
}
.side-bar-collapse-rtl > .ant-collapse-content > .ant-collapse-content-box {
  display: flex;
  align-items: flex-end;
}

.sidebar-ant-collapse-header > .ant-collapse-header {
  background-color: transparent;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin: 2px 8px 2px 8px !important;
  margin-right: 0 !important;
  display: flex !important;
  height: 100% !important;
  align-self: center !important;
}
.side-bar-collapse-rtl {
  background-color: transparent;
  width: 100%;
}

.side-bar-collapse-rtl
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  display: flex;
  padding-left: 0 !important;
  padding-right: 64px !important;
  align-items: baseline !important;
}
.side-bar-collapse
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  display: flex;
  padding-left: 64px !important;
  padding-right: 0 !important;
}
.active-ant-collapse-header > .ant-collapse-header .ant-collapse-arrow svg {
  color: #ffa351 !important;
  width: 16px !important;
  height: 16px !important;
}
.active-rtl-ant-collapse-header > .ant-collapse-header .ant-collapse-arrow svg {
  color: #ffa351 !important;
  width: 16px !important;
  height: 16px !important;
}
.rtl-sidebar-ant-collapse-header
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  color: #aeaeb2 !important;
  width: 16px !important;
  height: 16px !important;
}
.sidebar-ant-collapse-header > .ant-collapse-header .ant-collapse-arrow svg {
  color: #aeaeb2 !important;
  width: 16px !important;
  height: 16px !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;