.wrapper, .rtl
  display: flex
  flex-direction: column
  .customerInformationWrapper
    width: 37rem
    border-radius: 5px
    border: solid 1px #d9d9d9
    background-color: #ffffff
    display: flex
    flex-direction: column
    padding: 2.4rem
    .title
      font-size: 1.8rem
      font-weight: 500
      color: #1a1a1a
    .contact
      display: flex
      flex-direction: column
      width: 100%
      .contactAndInfoWrapper
        width: 100%
        padding: 0 17px
        align-items: center
        margin: 4px 0
        display: flex
        flex-direction: row
        .totalWrapper
          display: flex
          flex-direction: row
          .totalOrder,.total
            font-size: 1.6rem
            font-weight: 500
            color: #a6a6a6
          .total
            color: #1a1a1a
            margin: 0 .2rem !important
        .email
          font-size: 1.6rem
          font-weight: 500
          color: #1a1a1a
        .phoneCall
          width: 1.6rem
          height: 1.7rem
        .phoneWrapper
          display: flex
          flex-direction: row
          .phone
            font-size: 1.6rem
            font-weight: 500
            color: #1a1a1a
          &.rtl
            direction: rtl
    .content
      display: flex
      flex-direction: row
      padding: 2.4rem 0
      .contentWrapper
        display: flex
        flex-direction: column
        .infoWrapper
          display: flex
          flex-direction: column
          padding: .5rem .3rem
          align-self: flex-start
          .name
            font-size: 1.6rem
            font-weight: 900
            color: #1a1a1a
          .address
            font-size: 1.4rem
            color: #1a1a1a
        .userInfoWrapper
          display: flex
          flex-direction: column
          .totalWrapper
            display: flex
            flex-direction: row
            padding: .4rem  0
            .totalOrder,.total
              font-size: 1.6rem
              font-weight: 500
              color: #a6a6a6
            .total
              color: #1a1a1a
              margin: 0 .2rem !important

          .phone
            font-size: 1.6rem
            font-weight: 500
            color: #1a1a1a
            padding: 2.8rem 0 0 0
          .email
            font-size: 1.6rem
            font-weight: 500
            color: #1a1a1a
            padding: 0.8rem 0
            padding-top: .5rem
        .contactWrapper
          width: 3.2rem
          border-radius: 2rem
          background-color: white
          position: relative
          display: flex
          flex-direction: column
          top: 0
          justify-content: center
          align-items: center
          .phoneCall
            width: 1.6rem
            height: 1.7rem
            margin-top: 2rem
            margin-bottom: 1.3rem
          .mail
            width: 1.6rem
            height: 1.6rem
            margin-bottom: 1.3rem
          .order
            width: 1.6rem
            height: 1.6rem
            margin-top: 1.3rem
        .avatarWrapper
          border: 1px solid #e6e6e6
          z-index: 1
          position: relative
          width: 4.9rem
          height: 4.9rem
          border-radius: 3.2rem
          background-color: #f09440
          display: flex
          justify-content: center
          align-items: center
          font-size: 1.8rem
          .customerImage
            width: 4.9rem
            height: 4.9rem
            border: 1px solid #e6e6e6
            border-radius: 3.2rem
.rtl
  .customerInformationWrapper
    .contactAndInfoWrapper
      .phoneWrapper
        direction: rtl

    .content
      .contentWrapper
        .userInfoWrapper
          .phone
            direction: rtl
