.branches-performance-table table .ant-table-tbody .ant-table-row-level-0 > td {
  padding: 0 12px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  // box-shadow: inset 0 -1px 0 0 #f2f2f7 !important;
  background-color: #f2f2f7 !important;
  height: 40px !important;
}

.ant-table-measure-row {
  visibility: collapse !important;
}
.branches-performance-table {
  width: 100% !important;
  height: 100% !important;
  border: 0.1px solid #f2f2f7 !important;
  border-top: 0 !important;
}
.branches-performance-table table > tbody {
  border: 0.1px solid #f2f2f7;
}
.branches-performance-table
  table
  .ant-table-tbody
  .ant-table-row-level-0:hover
  > td {
  box-shadow: inset 0 -1px 0 0 #f2f2f7 !important;
  background-color: #f2f2f7 !important;
  height: 40px !important;
}

.branches-performance-table table .ant-table-tbody > tr > td {
  width: 100% !important;
  padding: 0 12px !important;
}
.branches-performance-table table .ant-table-tbody {
  height: 480px !important;
  max-height: 480px !important;
}
.branches-performance-table .ant-table-body {
  height: 480px !important;
  max-height: 480px !important;
}
.branches-performance-table table .ant-table-tbody > tr > td span {
  padding-left: 0 !important;
}

.branches-performance-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  height: 40px !important;
  box-shadow: inset 0 0 0 1px #f2f2f7;
  background-color: #fff !important;
  padding: 18.5px 12px !important;
}
.branches-performance-table
  table
  thead
  > tr
  > .ant-table-cell
  .ant-table-cell-fix-right
  .ant-table-cell-scrollbar {
  display: none !important;
  width: 0 !important;
}
.branches-performance-table
  .ant-table-container
  table
  > thead
  > tr:last-child
  th:last-child {
  height: 40px !important;
  box-shadow: inset 0 0 0 1px #f2f2f7;
  background-color: #fff !important;
  padding: 18.5px 12px !important;
}

.branches-performance-table table .ant-table-thead > tr > th {
  box-shadow: inset 0 1px 1px 0 #f2f2f7;
  height: 40px !important;
  padding: 18.5px 12px !important;
}

.ant-table-body {
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.ant-table-body::-webkit-scrollbar {
  background-color: #fff !important;
  width: 0 !important;
  height: 8px !important;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #bebec2 !important;
  border-radius: 10px !important;
}
// grouping table
.branches-performance-grouping-table
  table
  .ant-table-tbody
  .ant-table-row-level-0
  > td {
  padding: 0 12px !important;
  box-shadow: inset 0 -1px 0 0 #f2f2f7 !important;
  height: 40px !important;
}

.branches-performance-grouping-table
  table
  .ant-table-tbody
  .ant-table-row-level-0:hover
  > td {
  box-shadow: inset 0 -1px 0 0 #f2f2f7 !important;
  height: 40px !important;
}
.branches-performance-grouping-table table .ant-table-tbody > tr > td {
  width: 100% !important;
}
.branches-performance-grouping-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  height: 40px !important;
  box-shadow: inset 0 0 0 1px #f2f2f7;
  background-color: #fff !important;
  padding: 18.5px 12px !important;
}

.branches-performance-grouping-table
  .ant-table-container
  table
  > thead
  > tr:last-child
  th:last-child {
  height: 40px !important;
  // box-shadow: inset 0 0 0 1px #f2f2f7;
  background-color: #fff !important;
  padding: 18.5px 12px !important;
}

.branches-performance-grouping-table table .ant-table-thead > tr > th {
  box-shadow: inset 0 1px 1px 0 #f2f2f7;
  height: 40px !important;
  padding: 18.5px 12px !important;
}

.branches-performance-grouping-table {
  height: 100% !important;
  width: 100% !important;
  border: 0.1px solid #f2f2f7;
}

.branches-performance-grouping-table
  table
  thead
  > tr
  > .ant-table-cell
  .ant-table-cell-fix-right
  .ant-table-cell-scrollbar {
  display: none !important;
  width: 0 !important;
}

.row-branches-performance {
  height: 40px !important;
}

.branches-performance-grouping-table table .ant-table-tbody > tr > td span {
  padding-left: 0 !important;
}
.branches-performance-grouping-table .ant-table-body {
  height: 480px !important;
  max-height: 480px !important;
}
.branches-performance-grouping-table table .ant-table-tbody {
  height: 480px !important;
  max-height: 480px !important;
}

.branches-performance-grouping-table table .ant-table-tbody > tr > td {
  width: 100% !important;
  padding: 0 12px !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;