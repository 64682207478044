.wrapper
    display: flex
    flex-direction: column
    .title
        color: #000000
        font-size: 32px
        font-weight: bold
        margin-bottom: 16px
        display: flex
        flex-direction: row
        align-content: center
        .titleIcon
            width: 44px
            height: 44px
            display: flex
            border-radius: 4px
            background-color: #1c1c1e
            margin-right: 12px
            padding: 12px
            box-sizing: border-box
    .cardsWrapper
        display: flex
        flex-direction: row
        width: 100%
        margin-bottom: 48px
        justify-content: space-between
.rtl
    direction: rtl
    .title
        .titleIcon
            margin-left: 12px
            margin-right: 0
.desc
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 19px
    color: #8E8E93
    margin-bottom: 24px
