@import ../../../sass/colors

.cardWrapper
  display: flex
  flex-direction: column
  padding: 1rem
  border-bottom: 1px solid #E3E3E3
  .date
    width: 100%
    color: #9b9b9b
    font-size: 1.4rem
    display: flex
    align-items: center
    .logo
      width: 1.7rem
      height: 1.7rem
    .text
      margin: 0 1rem

  .reviewStatus
    display: flex
    align-items: center
    margin: 1rem 0
    .logo
      margin: 0
      width: 3.5rem
      height: 3.5rem
    .rateText
      margin: 0 1rem
      font-weight: bold
      color: #2D2D2D
      font-size: 1.6rem
  .reviewText
    font-size: 1.2rem
    font-weight: 500
    line-height: 1.42
    color: #2D2D2D
    .readMore
      color: #FDBB11
      cursor: pointer

.rtl.cardWrapper
  .reviewText
    display: flex
