.dropdownWrapper, .dropdown, .dropdownSelected
    display: flex
    width: 100%
    height: 56px
    align-items: center
    justify-content: space-between
    flex-direction: row
    padding: 0 18px
    padding-right: 0
    .dropdownContent
        display: flex
        flex-direction: row
        align-items: center
        .clicked
            width: 56px !important
            height: 56px !important
            display: flex !important
            justify-content: center !important
            align-items: center !important
            padding: 16px !important
            border-radius: 4px !important
            background-color: rgba(255, 163, 81, 0.12)

        .iconDropdown
            margin-right: 12px
            display: flex

        .dropdownTitle
            font-size: 16px
            color: #aeaeb2
        .arrowLeft,
        .arrowLeftClicked

            margin: 2px 8px 2px 0
        .arrowLeftClicked
            transform: rotate(90deg)
            margin-left: 8px !important
            transition: all 1s

.dropdown
    padding-right: 0
    justify-content: center
    display: flex
.dropdownSelected
    height: 56px
    // background-color: rgba(255, 163, 81, 0.12)
    // border-radius: 0 4.125rem 4.125rem 0
    // margin-right: 16px
    // margin-bottom: 10px
    .dropdownContent
        .dropdownTitle
            font-size: 16px
            font-weight: bold
            color: #ffa351

/// RTL
.rtl
    .dropdownContent
        .iconDropdown
            margin-left: 12px
