.wrapper
    background-color: #F2F2F7
    height: 40px
    border-radius: 30px
    border: 2px solid #E5E5EA
    display: flex
    flex-direction: row
    align-items: center
    width: fit-content
    .active, .inactive
        display: flex
        align-items: center
        justify-content: center
        background-color: #FFFFFF
        border-radius: 30px
        height: 100%
        padding: 8px 20px 8px 20px
        font-size: 16px
        font-weight: 600
        color: #1C1C1C
        cursor: pointer
    .inactive
        background-color: transparent
        color: #8E8E93
