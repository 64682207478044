@import ../../../../sass/colors

.wrapper
  display: flex
  width: 100%
  align-items: center
  background-color: white
  padding: 2rem 3rem
  color: $color-black-text
  cursor: pointer
  font-weight: bold
  .rank
    font-size: 2.2rem
    width: 5%
  .phone,.email
    font-size: 1.8rem
    flex: 1
  .email
    font-weight: normal
  .user,.pointsWrapper
    border-radius: 4rem
    display: flex
    align-items: center
    .imgWrapper
      display: flex
      justify-content: center
      align-items: center
      border-radius: 50%
      background-color: #FEBE19
      width: 2.5rem
      height: 2.5rem
      .img
        height: 70%
        width: 70%
    .userImg
      height: 4.5rem
      width: 4.5rem
      border-radius: 50%
    .userName,.points
      margin: 0 1rem
      font-size: 1.8rem
  .pointsWrapper
    width: 15%
  .user
    flex: 1
.wrapper:hover
  background-color: #F6F6F6
