.customFilterTitle
    margin-bottom: 8px
.customRangeDivider
    margin-top: 0px
    margin-bottom: 0px
.filterButtonsContainer
    margin-top: -10px
    width: 100%
    display: flex!important
    flex-direction: row
    justify-content: space-between!important
    align-items: center
.rangeErrorAlert
    margin-top: 20px!important