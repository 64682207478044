@import ../../../../sass/colors

input::placeholder
  font-size: 1.6rem
  font-weight: 400
.wrapper
  width: 53rem
  display: flex
  padding: 5rem 3rem
  align-items: flex-start
  flex-direction: column
  justify-content: space-between
  .header
    font-size: 2.4rem
    display: flex
    align-items: center
    width: 100%
    padding: 4rem 0 0
    cursor: pointer
    justify-content: space-between
    font-weight: 900
  .pragHeader
    margin: 0 0 4rem 0
    font-size: 1.6rem
    line-height: 1.25
    color: #9b9b9b

  .form
    width: 100%
    .separator
      margin: 3rem 0
    .btn
      background: linear-gradient(#febe19 0%, #feb817 48.56%, #fead12 100%)
      color: white
      display: flex
      margin: 4rem auto
      padding: 1.7rem 4rem
      align-items: center
      justify-content: center
      font-weight: 700
      font-size: 1.4rem
      border: none !important
      box-shadow: none !important
      outline: 0 !important
      border-radius: 4rem
      transition: all .2s

    .btn:hover
      transform: translateY(-3px)
      box-shadow: $box-shadow-btn-hover !important
    .btn:active
      transform: translateY(-1px)
.phoneNumberTitle
  color: #2D2D2D
  font-weight: 700
  font-size: 1.6rem
.phoneNumberFeildContainer
  display: flex
  flex-direction: row
  align-items: center
.countryCode
  position: relative
  width: 35%
  margin-right: 16px
.emptyFeildContainer
  position: absolute
  top: 43px
  left: 12px
  right: 4px
  display: flex
.phoneNumberFeild
  height: 52px
  margin: 16px 0
.countryCodeRtl
  position: relative
  margin-left: 16px
.arrowContainerEn
  position: absolute
  top: 50px
  right: 20px
.arrowContainerAr
  position: absolute
  top: 50px
  left: 20px

  
