.main
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between
  border-bottom: 1px solid #d8d8d8
  height: 6.4rem
  align-items: center
  padding: 0 2.4rem
  cursor: pointer

  &, *
    transition: .2s all

  .text
    // padding: 2rem 2.4rem
    font-size: 1.8rem
    font-weight: 700
    color: #1a1a1a
    width: 100%
    display: flex
    align-items: center
    gap: 4px
    flex-direction: row
  .checkBoxWrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-around
    width: 50%
    .checkBox, .disable
      width: 3.2rem
      height: 3.2rem
      border-radius: .4rem
      border: solid .15rem #595959
      background-color: #fff
      display: flex
      align-items: center
      justify-content: center
    .leftCheckBox
      // margin: 0 7.5rem
    .rightCheckBox
      // margin: 0 1rem
    .disable
      opacity: .5
      cursor: not-allowed
    img
      opacity: 0
      width: 1.8rem

  &.active,
  &:hover
    background-color: rgba(240, 148, 64, .1)

.activePickup
  background-color: #f09440 !important

  img
    opacity: 1 !important

.activeDelivery
  background-color: #f09440 !important

  img
    opacity: 1 !important
