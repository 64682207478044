.wrapper
    box-shadow: inset 0 -1px 0 0 #f2f2f7
    width: 100%
    background-color: #ffffff
    border-radius: 4px
    border: solid 1px #e5e5ea
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    .header
        padding: 24px
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center
        border-bottom: solid 1px #e5e5ea
        .headerTitle
            display: flex
            flex-direction: row
            align-items: center
            .liveUpdatesIconWrapper
                width: 26px
                height: auto
                .liveUpdatesIcon
                    width: 100%
                    height: 100%

            .title
                flex-grow: 0
                font-size: 24px
                font-weight: bold
                margin: 0px 8px
    .loaderBody
        display: flex
        padding-top: 2rem
        width: 100%
        height: 73vh
        justify-content: center
    .body
        padding-left: 24px
        display: flex
        width: 100%
        height: 73vh
        flex-direction: column
        .scroll
            overflow-y: scroll
            scroll-behavior: smooth
            padding-right: 24px
            .card, .lastCard
                padding: 24px 0
                width: 100%
                display: flex
                border-bottom: solid .1rem #e5e5ea
            .lastCard
                border-bottom: 0
        .scroll::-webkit-scrollbar
            width: 4px

        .scroll::-webkit-scrollbar-thumb
            background-color: #e5e5ea

.newMessage
    display: flex
    margin: 0
    justify-content: center
    align-items: center
    .icon
        margin-bottom: 0.3rem
        margin-left: 0.5rem
    .text
        margin-left: 0.7rem
        margin-right: 0.7rem

@keyframes shake
    10%, 90%
        transform: translate3d(-1px, 0, 0)
    20%, 80%
        transform: translate3d(2px, 0, 0)
    30%, 50%, 70%
        transform: translate3d(-4px, 0, 0)
    40%, 60%
        transform: translate3d(4px, 0, 0)

.rtl
    .body
        padding-left: 0
        padding-right: 24px
        .scroll
            padding-left: 24px
            padding-right: 0
