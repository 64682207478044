button
  border: none
.genericButtonDefaults
  &,
  &:active,
  &:focus
    outline: 0
  
  transition: all .2s

  &:hover
    transform: translateY(-3px)
  &:active
    transform: translateY(-1px)

