.wrapper, .rtl
  display: flex
  flex-direction: column
  .title
    font-size: 1.8rem
    font-weight: 500
    color: #1a1a1a
  .seeMore
    font-size: 14px
    font-weight: 500
    cursor: pointer
    color: #41126c
    text-decoration: underline
    margin-top: .8rem
  .zonesWrapper
    margin-top: 1.6rem
    display: flex
    flex-direction: column
    .city
      font-size: 1.6rem
      color: #000000
    .area
      font-size: 1.8rem
      font-weight: 900
      color: #000000
  .zonesCount
    font-size: 1.4rem
    margin-top: 2.4rem
    font-weight: 900
    color: #1a1a1a
  .deliveryWrapper
    display: flex
    flex-direction: row
    margin-top: 2.4rem
    .deliveryCostWrapper
      display: flex
      flex-direction: row
      .img
        width: 3.2rem
        height: 3.2rem
      .costWrapper
        margin-left: .8rem
        display: flex
        flex-direction: column
        .costTitle
          font-size: 1.2rem
          color: #595959
        .costSubTitle
          font-size: 1.6rem
          font-weight: 900
          color: #1a1a1a

.rtl
  .deliveryWrapper
    .deliveryCostWrapper
      .img
        margin: 0 1rem
