@import ../../../../sass/colors

.table
  width: 100%
  display: flex
  flex: 1
  border-bottom: 3px solid #e3e3e3
  flex-direction: column
  align-items: flex-start
  .tableContent
    flex: 1
    display: flex
    flex-direction: column
    margin: 0
    padding: 1.5rem 3rem
    min-height: 20rem
    width: 100%
  .tableHeader
    width: 100%
    display: flex
    align-items: center
    // z-index: 1
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.1)
    background: #feb716
    height: 5.5rem
    justify-content: space-between
    font-size: 1.4rem
    margin: 0
    padding: 0 10.2rem
    color: white
    font-weight: 900
    letter-spacing: 1px
    text-transform: uppercase
    p
      margin: 0
    .phone
      flex: 1.5
      display: flex !important
      justify-content: flex-start !important
    .name
      flex: 2
      display: flex !important
      justify-content: flex-start !important
    .branch
      font-size: 1.6rem
      flex: 2
      display: flex !important
      justify-content: flex-start !important

.addBranch
  background: linear-gradient(#febe19 0%, #feb817 48.56%, #fead12 100%)
  display: flex
  align-items: center
  justify-content: space-between
  border: none !important
  box-shadow: none !important
  outline: 0 !important
  padding: 1.3rem 1.6rem
  color: white
  border-radius: 4rem
  transition: all .2s
  span
    margin: 0 .25rem
    font-size: 1.8rem
  .text
    margin: 0 .25rem
    font-size: 1.4rem
    font-weight: 700

  .addBranch:hover
    transform: translateY(-3px)
    box-shadow: $box-shadow-btn-hover !important
  .addBranch:active
    transform: translateY(-1px)

.notMatch
  margin: auto
  font-size: 2rem
  font-weight: bold
