@import ../../../../sass/colors

input::placeholder
  font-size: 1.6rem
  font-weight: 400

.wrapper
  width: 53rem
  display: flex
  padding: 5rem 0
  align-items: flex-start
  flex-direction: column
  justify-content: space-between
  .line
    width: 7rem
    height: 2px
    margin: 1rem 0 4rem
    background: #fdbb11
  .header
    font-size: 2.4rem
    display: flex
    align-items: center
    width: 100%
    padding: 3rem 3.5rem
    cursor: pointer
    justify-content: space-between
    font-weight: 900
  .form
    width: 100%

    .separator
      margin: 3rem 0

    .toggleWrapper
      display: flex
      justify-content: space-between
      align-items: center

    .toggleText
      font-size: 1.7rem
      font-weight: 600

    .infoIcon
      margin-left: 0.5rem
      font-size: 1.4rem
      color: #808080
      cursor: pointer
      font-size: 1.5rem


    .btn
      background: linear-gradient(#febe19 0%, #feb817 48.56%, #fead12 100%)
      color: white
      display: flex
      margin: 2rem auto
      padding: 1.7rem 4rem
      align-items: center
      justify-content: center
      font-weight: 700
      font-size: 1.4rem
      border: none !important
      box-shadow: none !important
      outline: 0 !important
      border-radius: 4rem
      transition: all .2s

    .btn:hover
      transform: translateY(-3px)
      box-shadow: $box-shadow-btn-hover !important
    .btn:active
      transform: translateY(-1px)
