.newTransactionWrapper
    animation: newTransaction 0.6s ease-in-out
.wrapper
    display: flex
    flex-direction: column
    width: 100%
    position: relative
    .solveButtonSection
        position: absolute
        bottom: 0
        right: 0
        .solveButton
            color: #503E9D !important
            height: 39px
            border: 1px solid #503E9D
    .reviewWrapper
        display: flex
        flex-direction: column
        .text
            margin-top: .8rem
            font-size: 1.4rem
            display: inline-block !important
            color: #1c1c1e
            .readMore
                display: inline-block !important
                color: #000
                text-decoration: underline
                font-size: 1.4rem
                font-weight: bold
                margin: 0 5px
                cursor: pointer

    .invoiceWrapper
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        .viewOrderDetailsLink
            display: flex
            flex-direction: row
            align-items: center
            padding: 0 1.2rem 0 0
            margin-right: .4rem
            border-radius: .4rem
            cursor: pointer
            .arrowRight
                width: 1.6rem
                height: 1.6rem
            .viewOrderDetailsTitle
                font-size: 1.4rem
                font-weight: bold
                color: #1c1c1e
        .totalInvoice
            font-size: 1.4rem
            font-weight: bold
            color: #000000
    .redeemedItemsWrapper
        display: flex
        flex-direction: column
        .redeemedItemWrapper
            display: flex
            flex-direction: row
            align-items: center
            .redeemedItemImagePlaceholderWrapper
                display: flex
                justify-content: center
                align-items: center
                width: 3.2rem
                height: 3.2rem
                border-radius: 7.2rem
                box-shadow: inset 0 0 1px 0 #f2f2f7
                background-color: #e5e5ea
                margin-right: .8rem
                .redeemedIcon, .redeemedIconPlaceholder
                    width: 100%
                    border-radius: 7.2rem
                    height: 100%
                .redeemedIconPlaceholder
                    width: 1.6rem
                    border-radius: 0 !important
                    height: 1.6rem
            .redeemedItemTitle
                font-size: 1.4rem
                color: #000000
    .itemsWrapper
        display: flex
        flex-direction: column
        margin-bottom: 1.9rem
        .item, .moreItems
            font-size: 1.4rem
            color: #8e8e93
        .moreItems
            cursor: pointer
            color: #000
            text-decoration: underline
    .orderTypeWrapper
        display: flex
        flex-direction: row
        align-items: center
        margin-bottom: .8rem

        .orderTypeIcon
            width: 1.6rem
            height: 1.6rem
            margin-right: .8rem
        .orderTypeTitle
            font-size: 1.4rem
            font-weight: bold
            color: #8e8e93
    .solveBtn
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        padding: 10px 32px
        height: 39px
        background-color: #fff
        border-radius: 4px
        border: solid 1px #503e9d
        font-size: 14px
        font-weight: bold
        color: #503e9d
    .userWrapper
        display: flex
        flex-direction: row
        align-items: flex-start
        margin-top: 1.6rem
        margin-bottom: .8rem
        .categoriesAndTagsChips
            margin-top: .5rem
            display: flex
            flex-direction: row
            align-items: center
            .categoriesChips
                display: flex
                flex-direction: row
                align-items: center
                .categoryChip,.tagChip, .categoryRedChip, .tagRedChip
                    display: flex
                    justify-content: center
                    align-items: center
                    margin-right: 1rem
                    padding: .4rem 1.2rem
                    border-radius: 14rem
                    background-color: #38c172
                    .category
                        font-size: 1.2rem
                        color: #ffffff
                .tagRedChip
                    background-color: #fdeaea
                    .tag
                        color: #ee5253
                .categoryRedChip
                    background-color: #ee5253
                .tagChip
                    background-color: #e7f8ee
                    .tag
                        font-size: 1.2rem
                        color: #38c172
        .telephone
            font-size: 1.4rem
            color: #000000

        .avatarWrapper
            width: 4rem
            height: 4rem
            gap: 1rem
            margin: 0 .8rem 0 0
            border-radius: 7.2rem
            background-color: #e5e5ea
            display: flex
            align-items: center
            justify-content: center
            position: relative
            .userIcon, .userIconPlaceholder
                width: 2.4rem
                height: 2.4rem
            .userIconPlaceholder
                width: 99% !important
                height: 99% !important
                border-radius: 7.2rem

            .emoji
                width: 2rem
                height: 2rem
                position: absolute
                bottom: 0
                right: 0
    .headerWrapper
        width: 100%
        align-items: center
        display: flex
        flex-direction: row
        justify-content: space-between
        .headerTitle
            font-size: 1.4rem
            color: #8e8e93
.tooltipText
    font-size: 1rem
    color: #ffffff

@keyframes newTransaction
    0%
        opacity: 0
        transform: translateY(-40px)
    100%
        opacity: 1
        transform: translateY(0px)
.rtl
    .reviewWrapper
    .redeemedItemsWrapper
        .redeemedItemWrapper
            .redeemedItemImagePlaceholderWrapper
                margin-right: 0
                margin-left: 0.8rem
    .orderTypeWrapper
        .orderTypeIcon
            margin-right: 0
            margin-left: 0.8rem
    .userWrapper
        .categoriesAndTagsChips
            .categoriesChips
                .categoryChip,.tagChip, .categoryRedChip, .tagRedChip
                    margin-right: 0
                    margin-left: 1rem
        .avatarWrapper
            margin: 0 0 0 0.8rem
