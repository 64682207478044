.topBranches
    font-size: 16px
    font-weight: 600
    color: #1c1c1e
.branchName
    font-size: 14px
    color: #1c1c1e
    margin: 0
.percentageWrapper
    display: flex
    flex-direction: row
    align-items: center
    .percentageIcon
        width: 13px
        height: 13px
    .percentage
        font-size: 14px
        color: #1c1c1e
.statusWrapper
    display: flex
    align-items: center
    flex-direction: row
    .onTrackDot,
    .offTrackDot
        width: 13px
        height: 13px
        flex-grow: 0
        border-radius: 56px
        background-color: #38c172
        margin-right: 8px
    .offTrackDot
        background-color: #ee5253

    .statusTitle
        font-size: 14px
        color: #1c1c1e

.titleWrapper
    display: flex
    flex-direction: row
    align-items: center
    width: 100%
    .title
        font-size: 14px !important
        font-weight: bold !important
        color: #8e8e93 !important
    .sortingWrapper
        display: flex
        flex-direction: column
        margin-left: 12px
        width: 30%
        .chevronIcon
            width: 12px
            height: 12px
.branchTitle
    font-size: 14px
    font-weight: bold
    color: #8e8e93
.emptyTextWrapper
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    .emptyIcon
        width: 88px
        height: 88px
    .emptyText
        font-size: 16px
        color: #000000
        margin-top: 24.66px
