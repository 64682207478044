button
  border: none

.genericButtonDefaults
  background-color: #fff
  transition: .2s all
  display: flex
  justify-content: center
  align-items: center
  .icon
    margin: -2px 7px
  &:hover
    background-color: #F2A158 !important
    color: #ffffff !important
  &:disabled
    pointer-events: none
