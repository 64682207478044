.wrapper
    width: 100%
    background: #FFFFFF
    box-shadow: inset 0px 1px 0px #F2F2F7
    padding-top: 24px
    border-radius: 4px
    border: solid 1px #e5e5ea
    margin-right: 24px
    display: flex
    flex-direction: column
    .iconWrapper
        width: 40px
        height: 40px
        background-color: #F2F2F7
        display: flex
        justify-content: center
        align-content: center
        margin-bottom: 16px
        margin-left: 24px
        .icon
            width: 20px
    .informationCellsWrapper
        display: flex
        flex-direction: row
        padding-left: 24px
        .informationWrapper
            display: flex
            flex-direction: column
            justify-content: flex-start
            justify-items: flex-start
            align-items: flex-start
            justify-content: flex-start
            margin-right: 24px
            .title
                font-size: 16px
                line-height: 22px
                color: #1C1C1E
                margin-bottom: 4px
            .bodyText
                font-weight: bold
                font-size: 24px
                line-height: 33px
                color: #1C1C1E
                margin-bottom: 4px
            .desc
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 19px
                color: #8E8E93
                margin-bottom: 24px
    .separator
        width: 100%
        height: 1px
        background-color: #F2F2F7
    .callToActionContainer
        display: flex
        justify-content: flex-end
        height: 40px
        .callToActionWrapper
            padding: 0 12px
            display: flex
            align-items: center
            flex-direction: row
            &:hover
                background-color: #F9F9FB
        .callToAction
            font-style: normal
            font-weight: bold
            font-size: 14px
            line-height: 19px
            color: #FFA351
            margin-right: 5px
        .callToAction:hover
            text-decoration: none
.rtl
    direction: rtl
    margin-right: 0px
    .iconWrapper
        margin-left: 0
        margin-right: 24px
    .informationCellsWrapper
        padding-left: 0px
    .callToActionContainer
        .callToActionWrapper
        .callToActionArrow
            transform: rotate(180deg)
