@import ../../sass/colors


.forOFor
  margin: auto auto 0
  font-size: 7rem
  color: red
  font-weight: lighter
.text
  margin: 0 auto 0
  font-size: 5rem
  font-weight: bold
.btn
  margin: 2rem auto auto
  border: none
  outline: none !important
  color: white
  font-size: 1.8rem
  width: 13.5rem
  height: 5.5rem
  border-radius: 3rem
  background: linear-gradient(#febe19 0%, #feb817 48.56%, #fead12 100%)
