.inputWrapper
    display: flex
    justify-content: space-between
    align-items: center
    .inputWithLabel
        display: flex
        flex-direction: row
        align-items: center
        background-color: #f2f2f7
        border-radius: 4px
        padding: 8px 16px
        height: 40px
        &:hover
            border: 1px solid #ffbd7a
        .label
            font-size: 14px
            color: #1C1C1E
        .currency
            font-size: 14px
            color: #8E8E93
