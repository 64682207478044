.wrapper
  width: 46rem
  border-radius: 0.5rem
  box-shadow: 0 1.2rem 2.4rem 0 rgba(0, 23, 59, 0.1)
  font-size: 1.4rem
  font-weight: 900
  letter-spacing: 0.32px
  padding: 1.6rem
.success
  background-color: #38c172
  color: #ffffff
.error
  background-color: #db3030
  color: #ffffff
.loading
  background-color: #f7cc49
  color: #1a1a1a

