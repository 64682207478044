.ant-table-thead tr th {
  background-color: #fff !important;
  color: #8e8e93 !important;
  font-weight: 700 !important;
}

.ant-table-thead tr th::before {
  display: none;
}

.ant-table-column-sorters {
  justify-content: flex-start !important;
  .ant-table-column-sorter-inner {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.insights-table-row {
  color: #1c1c1e;
  font-weight: 400;
  .ant-table-cell > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-table-row {
    cursor: pointer;
  }
}

.insights-header-search-input {
  height: 100%;
  margin: 0 !important;
  width: 330px !important;
}

.export-button {
  height: 100% !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;