.wrapper
    display: flex
    width: 100%
    flex-direction: column
    margin-top: 81px
    .title
        font-size: 22px
        font-weight: 600
        color: #000
        margin-bottom: 8px
    .description
        font-size: 16px
        font-weight: 400
        color: #8E8E93
        margin-bottom: 16px
    .cards
        display: flex
        flex-direction: row
        align-items: flex-start
        flex-wrap: wrap
        gap: 24px
