.main
    display: flex
    flex-direction: column
    padding-left: 24px
    width: 100%
    align-items: center
    height: 624px
    .headerWrapper
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        width: 100%
        padding: 24px
        border-top: solid 1px #f2f2f7
        border-left: solid 1px #f2f2f7
        border-right: solid 1px #f2f2f7
        border-radius: 5px 5px 0 0
        background-color: #fff
        .headerTitle
            font-size: 20px
            font-weight: bold
            color: #1c1c1e

    .analyzeBranches
        padding: 10.5px
        display: flex
        align-items: center
        justify-content: flex-end
        width: 100%
        flex-direction: row
        font-size: 14px
        font-weight: bold
        color: #ffa351
        text-decoration: none
        border-bottom: solid 1px #f2f2f7
        border-left: solid 1px #f2f2f7
        border-right: solid 1px #f2f2f7
        border-radius: 0 5px 5px 0
        background-color: #fff
        &:hover
            color: #ffa351
            text-decoration: none

        .arrowIcon
            width: 16px
            height: 16px
            margin-left: 4px
            // &.rtl

.rtl
    padding-right: 24px
    padding-left: 0
    .analyzeBranches
        .arrowIcon
            transform: rotate(180deg)
            margin-left: 0
            margin-right: 4px
