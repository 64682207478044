.wrapper, .pickupWrapper
  display: flex
  flex-direction: column
  min-height: 145rem
  margin-bottom: 1rem
  overflow: hidden !important
  padding: .3rem 0
  .separator
    margin: 1rem 0
.pickupWrapper
  min-height: 97rem
