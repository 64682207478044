.wrapper
  width: 46rem
  border-radius: 5px
  margin-top: .5rem
  border: solid 1px #d9d9d9
  background-color: #ffffff
  background-color: #ffffff
  overflow: scroll
  overflow-y: none !important
  padding: 0 2.3rem
  .header
    display: flex
    flex-direction: row
    padding: 2.3rem 0
    justify-content: space-between
    .title
      font-size: 1.8rem
      font-weight: 500
      color: #1a1a1a
    .btn
      font-size: 1.6rem
      font-weight: 500
      line-height: 1.13
      letter-spacing: 0.11px
      text-align: center
      color: #41126c
      background-color: transparent !important
      outline: none !important

      p
        border-bottom: 1px solid #41126c
    .btnIcon
      width: 2.4rem
      height: 2.4rem
      border-radius: 1.2rem
      background-color: rgba(65,18,108, 0.35)
      margin: 0 0.7rem
  .comment
    display: flex
    flex-direction: column
    border-top: 0.2rem solid rgba(31, 4, 48, 0.1)
    border-bottom: 0.2rem solid rgba(31, 4, 48, 0.1)
    justify-content: space-between
    width: 100%
    padding: 2.3rem
    .commentHeader
      font-size: 1.4rem
      font-weight: bold
      color: #1a1a1a
    .commentSubHeader
      font-size: 16px
      color: #db3030
  .wrap
    border-top: 0.2rem solid rgba(31, 4, 48, 0.1)
    border-bottom: 0.2rem solid rgba(31, 4, 48, 0.1)
    width: 100%
    padding: 2.3rem
    .deliverFees
      span
        font-size: 1.4rem
        font-weight: 900
        color: #1a1a1a
    .payment
      display: flex
      flex-direction: row
      
      justify-content: space-between

      .promotionWrapper
        .promotionTitle
          font-size: 1.4rem
          font-weight: 900
          color: #1a1a1a
        .promo
          font-size: 1.2rem
          font-weight: 500
          line-height: 1.25
          color: #41126c
          margin: .5rem 0
        .promoCodeWrapper
          display: flex
          flex-direction: row
          position: relative
          top: 0
          .tooltip
            cursor: pointer
            display: flex
            align-items: center
            align-self: center
            .toolTipIcon
              width: 1.2rem
              height: 1.2rem
          .promoCode

            // width: 5.4rem
            height: 2.3rem
            border-radius: 4px
            background-color: rgba(65, 18, 108, 0.2)
            font-size: 1.4rem
            color: #41126c
            padding: 0 .5rem
            align-items: center
            text-transform: uppercase
      .method , .invoice
        span
          font-size: 1.4rem
          font-weight: 900
          color: #1a1a1a
        .beforePromo
          font-size: 1.2rem
          color: #a6a6a6
          text-decoration: line-through
        .totalInvoiceTitle
          font-size: 1.6rem
          color: #1a1a1a

      .paymentTitle
        font-size: 2rem
        position: relative
        top: 1.2rem
        color: #1a1a1a
      p
        font-size: 2rem
        color: #1a1a1a
  .btnWrapper
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center
    padding: 2.4rem 6.8rem

    .accept
      width: 14.4rem
      height: 4.8rem
      outline: 0
      border-radius: 5px
      background-color: #38c172
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ffffff
    .reject
      width: 14.4rem
      height: 4.8rem
      border-radius: 5px
      background-color: #db3030
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      outline: 0
      text-align: center
      color: #ffffff
  .noShowWrapper
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 2.4rem
    .noShowBtn
      width: 14.4rem
      height: 4.8rem
      border-radius: 5px
      background-color: #db3030
      font-size: 1.4rem
      font-weight: bold
      line-height: 1.29
      letter-spacing: 0.1px
      text-align: center
      color: #ffffff
      outline: 0
.wrapper::-webkit-scrollbar
  width: .3rem
.wrapper::-webkit-scrollbar-thumb
  background-color: #d9d9d9
  border-radius: 2.5px
