.wrapper
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 24px 30px
    width: 100%
    height: 847px
    background: #FFFFFF
    border-radius: 4px
    margin: 0px 0px
    border: solid 1px #f2f2f7
    .header
        width: 100%
        display: flex
        flex-direction: column
        .title
            display: flex
            justify-content: space-between
            align-items: center
            width: 100%
            .heading
                font-size: 24px
                font-weight: bold
            .totalRatings
                font-size: 20px
        .numbers
            margin-top: 15px
            display: flex
            justify-content: space-between
            padding: 2rem 0 2rem 2rem
            .firstNumber
                box-shadow: inset -1px 0px 0px #F2F2F7
                border-radius: 4px
                margin-right: 3.5rem
                margin-left: 0.5rem
            .number
                display: flex
                flex-direction: column
                width: 50%
                .key
                    font-size: 14px
                    font-weight: 400
                    margin-top: 1rem
                .value
                    font-size: 24px
                    font-weight: 700
                    margin-top: 0.3rem
                .solveWrapper
                    display: flex
                    flex-direction: row
                    align-items: center
                    cursor: pointer
                    .solveArrowIcon
                        width: 16px
                        height: 16px
                    .solveText
                        font-size: 14px
                        font-weight: bold
                        color: #ffa351
    .feedbackOverview
        display: flex
        flex-direction: row
        justify-content: space-between
        width: 100%
        margin-bottom: 2rem
        margin-top: 2rem
        .rateInsights
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            .emoji .image
                width: 32px
                height: 32px
            .text
                margin-left: 1rem
                margin-right: 1rem
                .number
                    font-size: 14px
                    color: #8E8E93
                .percentage
                    font-size: 14px
                    color: #1C1C1E
                    font-weight: bold
    .bottomBar
        width: 100%
        display: flex
        justify-content: flex-end
        text-decoration: none
        border-top: solid 1px #f2f2f7
        border-radius: 0 5px 5px 0
        background-color: #fff
        .analyzeCustomers
            padding: 10.5px
            font-size: 14px
            font-weight: bold
            color: #ffa351
            &:hover
                color: #ffa351
                text-decoration: none
            .arrowIcon
                width: 16px
                height: 16px
                margin-left: 4px
                margin-right: 4px

    .recentReviews
        display: flex
        width: 100%
        height: 59%
        flex-direction: column
        box-shadow: inset 0px 1px 0px #F2F2F7
        padding-top: 1rem
        .title
            font-size: 20px
            font-weight: bold
            padding: 10px 0
            display: flex
            align-items: center
        .scroll
            overflow-y: scroll
            scroll-behavior: smooth
            padding-right: 24px
            .card, .lastCard
                padding: 24px 0
                width: 100%
                display: flex
                border-bottom: solid .1rem #e5e5ea
            .lastCard
                border-bottom: 0
        .scroll::-webkit-scrollbar
            width: 4px

        .scroll::-webkit-scrollbar-thumb
            background-color: #e5e5ea
.rtl
    .header
        .numbers
            padding: 2rem 2rem 2rem 0
            .number
                .solveWrapper
    .bottomBar
        .analyzeCustomers
            .arrowIcon
                transform: rotate(180deg)
