.wrapper
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  height: 100vh
  background: #F9F9FB
  .logo
    margin-bottom: 1.6rem
  .loginFormBox 
    width: 440px
    height: 540px
    background: #fff
    border-radius: 4px
    padding: 40px
    box-shadow: 0px 4px 8px rgba(142, 142, 147, 0.12)
    box-sizing: border-box
    display: flex
    flex-direction: column
    align-items: center
    .form 
      width: 100%
      margin-top: 2rem
      display: flex
      flex-direction: column
      align-items: center
      .formInput
        width: 100%
        display: flex
        flex-direction: column  
        .label
          display: flex
          color: #1c1c1e
          position: relative
          justify-content: space-start
          width: 100%
          font-weight: 500
          font-size: 16px
          margin-bottom: .5rem
        .inputError
          border: 1px solid #FF3333
          transition: all .2s ease-in-out
        .input
          transition: all .2s ease-in-out
          background: #F2F2F7
          border-radius: 4px
          height: 56px
          margin-bottom: 2.7rem
          .error 
            margin-top: .4rem
            color: #FF3333
            font-size: 14px
            width: 100%
            text-align: center
          input 
            padding: 8px 16px 8px 16px
            border: 0px
            background: transparent
            width: 100%
            height: 100%
            outline: none
            font-size: 16px
            &::placeholder
              font-size: 16px
              color: #8e8e93
      .disabledFormButton
        background: rgba(255, 163, 81, 0.75) !important
      .formButton 
        margin-top: 1rem
        background: #FFA351
        border-radius: 4px
        font-size: 16px
        font-weight: bold
        color: white
        border: 0px
        outline: 0px
        height: 56px
        padding: 10px 24px
        width: 100%
        transition: all .2s ease-in-out
        &:active
          transform: scale(.95)
      .forgotPasswordLink 
        cursor: pointer
        font-size: 14px
        font-weight: 700
        color:  #FFA351
        line-height: 19.1px
        margin-top: 5rem
        border: 0px

  .override
    display: block
    margin: 0 auto
    border-color: red
