.row
    width: 100%
    margin-top: 2rem
    display: flex
    justify-content: flex-end
    align-items: center
    margin-bottom: 2rem
.row.en
    padding-right: 24px
.row.ar
    padding-left: 24px
    .input
        margin-right: 1rem
        margin-left: 1rem
.selectedFilters
    width: 100%
    height: 120px
    border-top: 1px solid #F2F2F7
    border-bottom: 1px solid #F2F2F7
    margin-top: 1rem
    padding: 2rem
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-wrap: wrap
    .resetAllButton
        padding: 0
        transform: translateY(-5px)
        margin-left: 1.5rem
    .tag
        color: #1C1C1E
        border: 1px solid #1C1C1E
        margin-left: .5rem
        margin-right: .5rem
        border-radius: 60px
        font-weight: bold
        align-items: center
        .xIcon
            margin-left: .5rem
            cursor: pointer
            color: #8E8E93
    .title
        font-size: 16px
        font-weight: 400
        margin-right: 1rem
