.container
    padding: 2.4rem
    display: flex
    flex-direction: column
    align-items: flex-end
    border: solid .1rem #e5e5ea
    border-top: 0

    .timeWrapper
        width: 100%
        display: flex
        justify-content: flex-end
        height: 3.2rem
        margin-bottom: 2.4rem
    .main
        display: flex
        flex-direction: row
        align-items: center
        width: 100% !important
        .separator
            width: .1rem
            height: 13.7rem
            margin: 0 4.8rem
            flex-grow: 0
            background-color: #f2f2f7
        .doughnutChartWrapper
            margin-right: 4.8rem
            display: flex
            flex-direction: row
            align-items: center
            width: 17.6rem
            height: 17.6rem
    &.rtl
        .doughnutChartWrapper
            margin-right: 0
            margin-left: 4.8rem
.customerGenderWrapper
    display: flex
    flex-direction: column
    .totalAndPercentage
        font-size: 2rem
        margin-top: 1.2rem
        font-weight: bold
        color: #000000
    .averageTicketSize
        font-size: 1.4rem
        margin-top: 3.2rem
        color: #1c1c1e

    .genderTitleWrapper
        display: flex
        flex-direction: row
        align-items: center
        .genderTitle
            font-size: 1.2rem
            color: #1c1c1e
        .dot
            margin-right: .8rem
            background-color: #000
            width: 1.2rem
            height: 1.2rem
            flex-grow: 0
            border-radius: 9rem
    &.rtl
        .dot
            margin-right: 0
            margin-left: .8rem
