@import ../../../sass/colors

.shadow
  position: relative

.shadow:before
  content: ""
  display: block
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background: rgba(0, 0, 0, .4)

.wrapper
  cursor: pointer
  border: none
  box-shadow: $box-shadow
  padding: 1.2rem
  background-color: white
  margin: 2.5rem
  display: flex
  flex-direction: column
  justify-content: space-between
  border-radius: .5rem
  width: 36.6rem
  height: 17rem
  transition: box-shadow 150ms linear
  .cardHeader
    display: flex
    justify-content: space-between
    align-items: flex-start
    .cardMetadata
      display: flex
      align-items: center
      .brandImg
        height: 6rem
        border-radius: 50%
      .alignment
        display: flex
        justify-content: flex-start
        .brandName
          font-size: 1.4rem
          margin: 0 .5rem
          p
            margin: -.2rem 0 0 0
            color: $color-grey-paragraph
            font-weight: 300
    .cardOptionsWrapper
      align-self: center
      margin-top: -3rem
      color: black
      img
        height: .5rem
  .cardStatsWrapper
    display: flex
    justify-content: space-around
    align-items: flex-end
    .stats
      border-right: 1px solid $color-border
      padding: 0 .8rem
      font-size: 1.4rem
      text-align: center
      h6
        margin: 0
        font-size: 2.2rem
      p
        margin: 0
    .payment
      align-self: center
      font-size: 1.4rem
      text-align: center
      h6
        margin: 0
        font-size: 1.5rem
        color: #FF0606
      p
        margin: 0

.switch
  font-size: 2rem !important
  border: none !important
  height: 3rem
  outline: none !important
  background-color: white !important
  text-align: center !important
  color: black !important
  box-shadow: none !important

.menuWrapper
  .item
    padding: 1rem
    text-align: left
    outline: 0 !important
  .item:hover
    background-color: rgba(254, 190, 25, .1)

.wrapper:hover
  box-shadow: $box-shadow-btn-hover

.active, .inActive, .inProgress, .closed
  color: #7ED321
  font-weight: 600
  margin: 0
  font-size: 1.4rem
  .dot
    margin: 0
  .dot:before
    margin: 0 .6rem
    content: '\25CF'
    font-size: 1.6rem

.active
  .dot:before
    color: #7ED321
.inActive
  color: #FEBE19
  .dot:before
    color: #FEBE19

.inProgress
  color: deepskyblue
  .dot:before
    color: deepskyblue

.closed
  color: #FF0606
  .dot:before
    color: #FF0606
