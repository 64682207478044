@import ../../sass/colors

.wrapper,
.collapseWrapper,
.headerWrapperWithDrawer
  top: 0
  right: 0
  position: fixed
  width: 100%
  display: flex
  flex-direction: column
  .dashboardPreviewAlert
    background-color: #41126c
    color: white
    display: flex
    flex-direction: column
    justify-content: center
    align-content: center
    width: 100vw
    padding: 5px 0
    z-index: 999999999999
    font-weight: 700
    font-size: 12px
    >span
      align-self: center
  .header
    height: 72px !important
    // margin-bottom: 32px !important
    padding: 0 32px 0 132px
    background-color: #fff
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    z-index: 11
    .menuIcon
      width: 32px
      height: 32px
      cursor: pointer
    .subRoutesContainer
      width: 86%
      display: flex
      flex-direction: row
      height: 100%
      align-items: center
      .pageTitle
        font-weight: 700
        font-size: 14px
        line-hight: 19px
        color: #1C1C1E
        height: 100%
        border-bottom: 1px solid #f2f2f2
        padding: 0 12px
      .subRoute
        min-width: 106px
        margin: 0 2px
        padding: 0 12px
        font-size: 14px
        font-weight: bold
        color: #8e8e93
        background-color: #fff
        outline: none
        cursor: pointer
        box-sizing: border-box
        height: 76px
        overflow: hidden
      .subRouteOnFocus
        background-color: #fffaf5
        border-bottom: 4px solid #ffa351
        box-sizing: border-box
        color: #ffa351
    .dropDown
      background-color: white
    .avatarContainer
      position: absolute
      right: 0px
      top: 20px
      .profileDropDownHeader
        display: flex
        justify-content: space-between
        align-content: center
        align-items: center
        background-color: white
        width: 60px
        outline: none
        .avatarDownArrow
          color: #8e8e93

      .userAvatar
        margin-right: 12px

  .switchLanguageToggleWrapper,.languageSelectIem
    height: 3.9rem
    display: flex
    border-radius: 5px
    border: solid 1px #ebebeb
    padding: 1.1rem .2rem
    .languageImage
      margin: 0 .8rem
      width: 3rem
    .languageLabel
      display: inline !important
      font-size: 1.4rem
    .arrowsWrapper
      display: flex !important
      flex-direction: column !important
      margin: 0 .8rem
      svg
        margin: -0.3rem
        font-size: 1.6rem
  .languageSelectIem
    border: none

// .customDropDownSelect
//   border-color: red
.rtl
  .header
    padding: 0 132px 0 32px
.collapseWrapper
  .header
    padding: 0 32px 0 282px
  &.rtl
    .header
      padding: 0 282px 0 32px
.headerWrapperWithDrawer
  .header
    padding: 0 32px
    .menuIcon
      margin-right: 40px
  &.rtl
    .header
      .menuIcon
        margin-right: 0
        margin-left: 40px
