@import ../../../../sass/colors

.wrapper
  max-height: 20rem
  overflow: auto
  width: 100%
  border-radius: 5px
  border: solid 1px #ebebeb
  background: white
  color: #9b9b9b
  font-size: 1.4rem
  line-height: 2rem
  padding: 1rem 2rem
  display: flex
  margin: -5px 0 5px

.btn
  display: flex
  margin: 0 0 5px
  align-items: center
  justify-content: space-between
  height: 6.5rem
  padding: 0 1.3rem
  width: 100%
  border-radius: 5px
  box-shadow: 0 1px 3px 0 rgba(26, 29, 12, 0.15)
  background: white
  outline: 0 !important
  border: 0
  *
    margin: 0
  .sp
    display: flex
    align-items: center
    .manager
      width: 2.5rem
      height: 2.5rem
      background: #FFA351
      margin: 0
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      font-size: 1.2rem
      font-weight: 500
      color: white
  .fp
    color: #2D2D2D
    font-size: 1.6rem
    font-weight: 900
    .date
      margin: 0 1rem
      color: #9b9b9b
      font-weight: 500
  .arrowO,.arrowC
    font-size: 3rem
    transition: 1s all
  .arrowC
    transform: rotate(90deg)
.rtl
  .arrowC
    transform: rotate(-90deg)
