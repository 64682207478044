@import ../../../../sass/colors

.notFound
  width: 100%
  display: flex
  flex: 1
  flex-direction: column
  align-items: center
  margin: 6rem 0
  justify-content: center
  img
    width: 7.5rem
    height: 7.5rem
  .text
    margin: 1rem 0
    font-size: 1.4rem
    color: #9B9B9B
