.row
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    section
        width: 100%
        padding: 1rem 2rem 2rem 2rem
    .notLastSection
        width: 100%
        padding: 1rem 2rem 2rem 2rem
        border-bottom: 1px solid #F2F2F7
    .title
        color: #8E8E93
        margin-bottom: 2rem
    .notFirstTitle
        margin-top: 2rem
        color: #8E8E93
        margin-bottom: 2rem
    .input
        width: 100%
        margin-top: 1.5rem
    .additionalInput
        margin-top: 1rem
        .additionalInputText
            font-size: 1.4rem
            font-weight: 500
            position: relative
            top: -.2rem
    .bottom
        width: 100%
        margin-top: 1rem
        padding: 0 2rem 4rem 2rem
        .button
            width: 100%
            padding-top: 2.25rem
            padding-bottom: 2.25rem
