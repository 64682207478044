.wrapper
    display: flex
    flex-direction: column
    justify-items: center
    align-items: center
    width: 100%
    box-sizing: border-box
    .iconWrapper
        display: flex
        flex-direction: column
        justify-items: center
        align-items: center
        justify-content: center
        background-color: #F2F2F7
        width: 80px
        height: 80px
        border-radius: 50%
        margin-bottom: 24px
        .downloadIcon
            width: 30px
            align-self: center
            justify-self: center
    .closeButtonWrapper
        margin-bottom: 24px
        align-self: flex-end
        .closeButton
            border-radius: 50%
            width: 32px
            height: 32px
            box-sizing: border-box
            outline: none
    .modalTitle
        font-weight: bold
        font-size: 20px
        line-height: 27px
        text-align: center
    .modalSubtitle
        font-style: normal
        font-weight: normal
        font-size: 16px
        line-height: 22px
        margin-bottom: 24px
    .timeFiltersWrapper
        width: 100%
        display: flex
        flex-direction: row
        justify-content: space-between
        margin-bottom: 24px
        .singleFilterWrapper
            display: flex
            flex-direction: column
    .timeFilterErrMsg
        width: 100%
        margin-bottom: 24px
.separator
    width: 100%
    height: 1px
    background-color: #F2F2F7
    margin-bottom: 16px

.csvWrapper
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center

.csvContainer
    background-color: #F2F2F7
    width: 80px
    height: 80px
    border-radius: 40px
    justify-content: center
    align-items: center
    display: flex
.uploadTitleContainer
    display: flex
    flex-direction: column
    align-items: center !important
    justify-content: center !important
    p.header
        font-weight: 700
        display: block !important
        text-align: center
        margin: 10px 0px 0px!important
        font-size: 20px
    p
        display: block !important
        text-align: center
        margin: 10px 0px 0px!important

.csvButtonContainer
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    align-self: center

.csvButtonWrapper
    background-color: #FFA351
    width: 25%
    margin: 8px
    position: relative
    justify-content: center
    align-items: center
    display: flex
    border-radius: 8px
    cursor: pointer

.csvUploadContainer
    height: 48px
    width: 100%
    display: flex
    flex-direction: row

.uploadImageContainer
    display: flex
    align-items: center
    justify-content: flex-end
    width: 35%

.customFileInput::-webkit-file-upload-button
    visibility: hidden

.customFileInput::before
    content: Upload CSV file
    display: inline-block

.uploadTitle
    color: #FFFFFF
    font-weight: 700

.downloadButtonContainer
    width: 25%
    height: 48px
    border-radius: 8px
    display: flex
    align-items: center
    justify-content: center
    background: #F9F9FB

.files_count
    color: #503E9D
    text-align: center
    font-weight: bold
    align-self: center
    align-items: center
    justify-content: center !important
    margin: 5px 0px 10px

.dowloadButtonContainer
    margin: 8px 0 0 8px

input[type=file]
    opacity: 0

.dowloadTempTitle
    color: #1C1C1C !important
    font-weight: 700
a
    color: #1C1C1C !important

.uploadTitleWrapper
    position: absolute
    top: 14px
    pointer-events: none

// .wrapper
//     margin-top: 24px
//     padding: 56px
//     border-radius: 10px
//     border: solid 1px #E5E5EA

.fileInfo
    display: flex
    align-content: center
    justify-content: space-between
    border: 1px solid #F2F2F7
    border-radius: 8px
    height: 60px
    padding: 0
    // width: 50%
    overflow: hidden
    margin-bottom: 20px
.imageWrapper
    background: #F2F2F7
    height: 60px
    width: 60px
    line-height: 60px
    text-align: center
.fileContent
    flex: 8
    p
        padding: 0px 5px
        text-align: left
        line-height: 60px
        font-weight: normal
        font-size: 12px
        margin: 0
        height: 60px
.buttonContainer
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: center
    button
        height: 48px
        padding: 0px 20px
        line-height: 48px
        border-radius: 4px
        font-weight: 700
        font-size: 16px
        outline: none
        border: none
        margin-left: 10px
        &:focus
            outline: none
        &.reset
            background: #FFA351
            color: #fff
        &.cancel
            background: #F2F2F7
            color: #1c1c1c

.okButtonContainer
    justify-content: center
    align-items: center
    display: flex
    margin-top: 16px

.okButtonStyles
    height: 52px
    width: 85%
    background-color: #38C172
    border-radius: 8px
    justify-content: center
    align-items: center
    display: flex

.okButtonText
    color: #FFF
    font-size: 18px
