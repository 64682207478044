.custom-dots {
  bottom: 0 !important;
}

.custom-carousel {
  width: 263.424px !important;
  height: 146px;
  overflow: hidden;
  border-radius: 9.216px;
}
.custom-dots li {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.custom-dots li.slick-active {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.custom-dots li button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  margin: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: #fff !important;
  opacity: 1 !important;
}

.custom-dots li.slick-active button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  margin: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.custom-carousel .slick-dots li.slick-active button {
  background: var(--active-dot-color) !important;
}

.custom-carousel .slick-list .slick-slide.slick-active {
  width: 263.424px !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;