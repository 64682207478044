.itemWrapper, .rtl
  width: 100%
  padding: 2.5rem 0
  border-bottom: 2px solid rgba(31, 4, 48, 0.1)
  &:last-child
    border-bottom: none
  .commentWrapper
    margin: .7rem 0
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 0 5.7rem
    .commentTitle
      font-size: 1.4rem
      font-weight: bold
      color: #1a1a1a
    .commentSubTitle
      font-size: 1.6rem
      color: #db3030
  .itemHeaders
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
    .itemNumber
      flex: 1
      font-size: 1.8rem
      font-weight: 900

      color: #1a1a1a
    .giftWrapper
      display: flex
      width: 100%
      flex: 6
      flex-direction: row
      align-items: center
      .giftIcon
        width: 2.1rem
        height: 2.6rem
      .giftContent
        display: flex
        margin: 0 .5rem
        flex-direction: column
        .giftHeader
          font-size: 1.6rem
          font-weight: bold
          color: #1a1a1a
        .giftSubHeader
          display: flex
          flex-direction: row
          align-items: center
          margin: .2rem 0
          .giftTitle
            font-size: 1.6rem
            color: #1a1a1a
          .giftCode
            display: flex
            justify-content: center
            align-items: center
            width: 5.5rem
            height: 2.7rem
            border-radius: 4px
            margin: 0 .8rem
            background-image: linear-gradient(to bottom, #681dae, #340e56)
            .giftCodeTitle
              font-size: 14px
              color: #ffffff

    .content
      flex: 4
      .header
        font-size: 1.6rem
        font-weight: bold
        color: #1a1a1a

    .price
      flex: 2
      span
        font-size: 1.4rem
        font-weight: 900
        color: #1a1a1a
        margin-left: 2.5rem
        margin-top: 0.3rem

  .mod
    display: flex
    flex-direction: row
    margin-bottom: 1.6rem
    .whiteSpace
      flex: 1
    .modNames
      flex: 4
      margin: 0.2rem -1.4rem
      .itemSpan
        font-size: 1.6rem

        color: rgba(89,89,89 , 0.7)

    .modPrices
      flex: 1
      .itemPriceSpan
        font-size: 1.4rem
        font-weight: 900

        color: #1a1a1a

.extras
  font-size: 1.4rem
  font-weight: bold

  color: #1a1a1a
  margin-top: 1rem
.catWrapper
  width: 100%
  display: flex
  justify-content: flex-end
  display: flex
  justify-content: flex-start
  padding: 0 5.7rem
  .categoryWrapper
    border-radius: 4px
    padding: 0 0.8rem
    display: inline-flex
    flex-direction: row
    background-color: rgba(65,18,108,0.2)
    .category
      font-size: 1.4rem
      font-weight: bold
      color: #41126c
    .separator
      font-size: 1.4rem
      font-weight: bold
      color: #41126c
      margin: 0 .5rem
  &.rtl
    border-radius: 4px
    padding: 0 0.8rem
    display: inline-flex
    flex-direction: row
    position: relative
    left: 6rem
    background-color: rgba(65,18,108,0.2)
.extrasSpace
  height: 3.5rem
  width: 1rem
