.customers-segment-skeleton .ant-skeleton-header .ant-skeleton-avatar {
  border-radius: 4px !important;
  width: 56px !important;
  height: 56px !important;
}

.customers-segment-skeleton .ant-skeleton-content .ant-skeleton-paragraph {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.customers-segment-skeleton .ant-skeleton-content {
  vertical-align: middle !important;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;