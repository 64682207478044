.container
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  width: 100%

.successfulImageIcon
  width: 100px
  height: 100px

.succssfulTitle
  margin-top: 16px
  margin-bottom: 16px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.fileUploadedTitle
  font-size: 20px
  color: #1C1C1C
  font-weight: 700

.fileMaxUpload
  font-size: 16px
  color: #503E9D
  font-weight: 400

.filenameContainer
  display: flex
  justify-content: center
  align-items: center
  gap: 8px

.fileNameStyle
  font-size: 16px
  color: #65656C
  margin-top: 6px

.buttonsContainer
  display: flex
  justify-content: center
  align-items: center
  flex-direction: row

.successBtn
  background-color: #38C172
  display: flex
  justify-content: center
  align-items: center
  flex-direction: row
  width: 74px
  padding: 8px 12px
  margin: 8px
  border-radius: 8px

.colseBtn
  background-color: #EE5253
  display: flex
  justify-content: center
  align-items: center
  flex-direction: row
  width: 74px
  padding: 8px 12px
  border-radius: 8px

.verifiedContainer
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: row
  margin-top: 8px

.confirmSendPointsContainer
  margin-right: 24px
  margin-top: 8px

.confirmSendPointsTitle
  color: #65656C
  font-size: 18px

.buttonIcon
  color: #FFF
  margin-top: 6px
.footer
  margin-top: 32px
  border: 1px solid #D1D1D6
  padding: 16px
  display: flex
  flex-direction: column
  justify-content: center
  border-radius: 8px
  gap: 24px
  .checkboxesWrapper
    display: flex
    flex-direction: column
    gap: 8px
    .checkboxWrapper
      background-color: #F2F2F7
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      padding: 12px 12px 12px 16px
      .checkboxLabel
        font-size: 16px
        font-weight: 400
        color: #1C1C1E
      .activeSwitch, .inactiveSwitch
        border: 1px solid #FFA351
      .inactiveSwitch
        border: 1px solid #8E8E93
