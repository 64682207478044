.cardWrapper, .rtl
    display: flex
    flex-direction: column
    justify-content: center
    .editBtn
        color: #65656C
        font-size: 16px
        font-weight: 400
        background-color: transparent
        border: none
        outline: none
        margin-top: 8px
        .editIcon
            margin-right: 8px
            width: 20px
            height: 20px
    .card, .selectCard
        display: flex
        height: 88.63px
        width: 160px
        border-radius: 8px
        justify-content: center
        align-items: center
        box-sizing: border-box
        outline: dashed 1px #8E8E93
        cursor: pointer
        margin: 0 !important
        padding: 0 !important
        box-sizing: border-box
        .image
            width: 100%
            height: 100%
            border-radius: 8px
            object-fit: cover
            object-position: center
            padding: 0
            margin: 0

            .plusIcon
                width: 32px
                height: 32px
    .selectCard
        border: 6px solid #FF9000
        outline: none
        .image
            border-radius: 0
.rtl
    .editBtn
        .editIcon
            margin-right: 0
            margin-left: 8px
