.pageWrapper
    width: 95%
    padding: 7rem 3rem 3rem 3rem
    min-height: 110vh
    .filtersRow
        width: 100%
        margin-top: 1rem
        display: flex
        justify-content: flex-end
        align-items: center
        margin-bottom: 2rem
        padding: 0 2rem
        .input
            margin-right: 1rem
            margin-left: 1rem
    .heading
        display: flex
        justify-content: flex-start
        .headingText
            font-weight: bold
            font-size: 2.6rem
    .tableWrapper
        width: 100%
        display: flex
        flex-direction: column
        justify-content: center
        background: #FFFFFF
        border: 1px solid #E5E5EA
        box-sizing: border-box
        border-radius: 4px
        margin-top: 3rem
        .tableLoader
            display: flex
            justify-content: center
            padding: 2rem
        .tableHead
            .tableHeadRowTwo
                padding: 2rem
                width: 100%
                margin-top: 2rem
                display: flex
                justify-content: flex-end
                align-items: center
            .tableHeadRowOne
                padding: 2rem
                margin-bottom: 2rem
                width: 100%
                display: flex
                justify-content: space-between
                align-items: flex-start
                .title
                    font-size: 2rem
                    display: flex
                    align-items: center
                    height: 100%
                .searchAndActions
                    display: flex
                    justify-content: flex-end
                    align-items: flex-start
                    width: 70%
                    .search
                        margin-right: 1rem
                        margin-left: 1rem
                        height: 55px
                        .searchInputIcon
                            margin-right: 0.5rem
                            margin-left: 0.2rem
                            margin-bottom: 0.35rem
                    .export
                        margin-right: 1rem
                        margin-left: 1rem
                        height: 55px
                        .exportButtonIcon
                            margin-right: 0.5rem
                            margin-left: 0.2rem
                            margin-bottom: 0.35rem

        .selectedTab
            background: rgba(255, 163, 81, 0.04)
            box-shadow: inset 0px -1px 0px #FFA351
