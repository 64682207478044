@import ../../../sass/colors

.overlay
  background: rgba(0,0,0,.1)
  .modal
    padding: 0
    min-width: 75vw
    display: flex
    min-height: 85vh
    border-radius: 20px
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
    overflow: hidden
    .wrapper
      padding: 0
      height: 100%
      width: 100%
      display: flex
      flex-direction: column
      border-radius: 5px
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1)
      overflow: hidden
      span
        margin: 0
      .rejectedText
        margin: auto
      .profileHeader
        border-radius: 5px 5px 0 0
        width: 100%
        padding: 1rem 2rem !important
        display: flex
        justify-content: space-between
        align-items: center
        height: 14rem
        background: #FFA351
        .user
          display: flex
          align-items: center
          .userImg
            width: 10.5rem
            height: 10.5rem
            border-radius: 50%
            padding: .2rem
            background-color: white
            border: 1px solid white
          .userData
            margin: 0 1rem
            display: flex
            color: white
            flex-direction: column
            justify-content: center
            align-items: flex-start
            .name
              font-size: 2.4rem
              font-weight: 900
              margin: 0
            .phone
              margin: 0
              font-size: 1.6rem
              font-weight: 900
            .mail,.old
              font-size: 1.4rem
              font-weight: 500
              margin: 0
            .mail
              margin: 5px 0 -3px
        .stats
          display: flex
          justify-content: space-between
          width: 50%
          align-items: center
          .statWrapper
            display: flex
            flex: 1
            flex-direction: column-reverse
            justify-content: center
            align-items: center
            text-align: center
            .title
              margin: 0
              font-size: 1.2rem
              color: #fee6a9
              font-weight: 500
            .number
              margin: 0
              font-size: 2rem
              color: white
              font-weight: 900
          .split
            width: 1px
            height: 4rem
            background: #f6f6f6
            opacity: .3
      .columnsWrapper
        display: flex
        justify-content: space-around
        height: 70vh
        margin: 2rem 0
        .pointOperationsWrapper,.redemptionOperationsWrapper,.ratingsWrapper
          width: 30%
          height: 100%
          border-radius: 5px 5px 0 0
          display: flex
          flex-direction: column
          .cardsWrapper,.cardsWrapperSkeleton
            overflow: auto
            border: 1px solid #ebebeb
            flex: 1
            border-radius: 10px
            .noData
              display: flex
              height: 100%
              width: 100%
              flex-direction: column
              justify-content: center
              align-items: center
              .img
                margin: .5rem 0
                width: 4rem
                height: 4rem
              .text
                text-align: center
                font-size: 1.8rem
          .title
            display: flex
            align-items: center
            height: 5rem
            color: #9b9b9b
            font-weight: 500
            text-transform: uppercase
            letter-spacing: .5px
            font-size: 1.8rem
            border-radius: 5px 5px 0 0

.cardsWrapperSkeleton
  justify-content: space-around

.cardWrapper
  display: flex
  flex-direction: column
  padding: 2rem
  min-height: 33.333%
  border-bottom: 1px solid #E3E3E3
  .date
    font-size: 1.6rem
    display: flex
    align-items: center
    .logo
      margin: 0 1rem
      width: 1.7rem
      height: 1.7rem
  .statsWrapper
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin: .5rem 0
    .status
      width: 35%
      .statusLabel
        color: #9B9B9B
        font-size: 1.4rem
      .statusValue
        font-size: 1.6rem

//redemptionOperationCardSkeletonStyles

.redemptionCardWrapper
  display: flex
  justify-content: space-between
  padding: 1.5rem
  align-items: center
  min-height: 33.333%
  border-bottom: 1px solid #E3E3E3
  .imgWrapper
    flex: 1
    display: flex
    flex-direction: column
    align-items: flex-start
    flex-wrap: wrap
    .img
      width: 6rem
      height: 6rem
      border-radius: 50%
    .imgName
      margin: 0 1rem
      font-size: 1.6rem

  .statsWrapper
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin: .5rem 0
    flex: 1
    .date
      width: 80%
      .dateLabel
        color: #9B9B9B
        font-size: 1.4rem
      .dateValue
        font-size: 1.5rem
    .status
      width: 50%
      .statusLabel
        color: #9B9B9B
        font-size: 1.4rem
      .statusValue
        font-size: 1.6rem

//ratingCardSkeletonStyles

.ratingCardWrapper
  display: flex
  flex-direction: column
  padding: 1rem 3rem
  min-height: 33.3333%
  border-bottom: 1px solid #E3E3E3
  .date
    font-size: 1.6rem
    color: #9B9B9B
  .reviewStatus
    display: flex
    align-items: center
    .logo
      margin: 0
      width: 3.5rem
      height: 3.5rem
    .rateText
      margin: 0 1rem
      font-size: 1.6rem
  .reviewText
    font-size: 1.2rem
    .readMore
      color: #FDBB11
      cursor: pointer
.rtl
  direction: rtl

.overlay
  background: rgba(0, 0, 0, .3)
  .modal
    padding: 0
    display: flex
    flex-direction: column
    border-radius: 20px
    border: solid 1px #979797
    position: relative
    overflow: hidden
    width: 56.5rem
    height: 48rem
    *
      margin: 0
    .layout
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.1)) !important
    .customerImage
      object-fit: fill
      width: 100%
      height: 100%
      z-index: -1
      position: absolute
      top: 0
      left: 0
    .closeWrapper
      width: 4.5rem
      height: 4.5rem
      z-index: 21
      border-radius: 50%
      background: rgba(246,246,246,.3)
      position: absolute
      top: 2rem
      right: 2rem
      display: flex
      align-items: center
      justify-content: center
      .close
        transform: rotate(45deg)
        width: 2.4rem
        height: 2.4rem
    .photoOf
      font-size: 2.2rem
      color: white
      position: absolute
      left: 2rem
      top: 2rem
      background: transparent
      .customerName
        margin: 0 0.6rem
        font-weight: 500
    .customerImageHeader
      display: flex
  .customerImagePopup
    &.rtl
      *
        direction: rtl
      .photoOf
        left: unset
        right: 2rem
      .closeWrapper
        right: unset
        left: 2rem
        width: 56.5rem
        height: 48rem
        min-width: unset
        min-height: unset
    img
      width: 100%
      height: 100%
