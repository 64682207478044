@import ../../sass/colors

.root
  width: 100%
  flex: 1
  padding: 0
  display: flex
  flex-direction: column
  font-family: Avenir, 'Avenir LT Std','Cairo','Roboto', sans-serif !important
  header
    box-shadow: none !important
.tab
  background-color: white !important
  font-family: Avenir, 'Avenir LT Std','Cairo','Roboto', sans-serif !important
  opacity: 1 !important
  flex: 1 !important
  outline: 0 !important
  border: none !important
  color: #9B9B9B !important
  border-bottom: 1px solid #e3e3e3 !important
  font-size: 1.8rem !important
  text-transform: none !important
  margin: 0 !important
  span
    span
      display: flex !important
      align-items: center !important
      justify-content: center !important

.selectedTab
  flex: 1 !important
  margin: 0 !important
  fill: #FFA351 !important
  text-transform: none !important
  background-color: white !important
  color: #FFA351 !important
  outline: 0 !important
  border: none !important

.indicator
  background-color: #FFA351!important
  height: 3px !important
  margin: 0 !important
