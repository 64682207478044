.pageWrapper
    width: 95%
    padding: 7rem 3rem 3rem 3rem
    min-height: 110vh
    .heading
        display: flex
        justify-content: flex-start
        .headingText
            font-weight: bold
            font-size: 2.6rem
    .tableWrapper
        width: 100%
        display: flex
        flex-direction: column
        justify-content: center
        background: #FFFFFF
        border: 1px solid #E5E5EA
        box-sizing: border-box
        border-radius: 4px
        margin-top: 3rem
        .tableLoader
            display: flex
            justify-content: center
            padding: 2rem
        .tableHead
            .tableHeadRowTwo
                padding: 2rem
                width: 100%
                margin-top: 2rem
                display: flex
                justify-content: flex-end
                align-items: center
            .tableHeadRowOne
                padding: 2rem
                margin-bottom: 2rem
                width: 100%
                display: flex
                justify-content: space-between
                align-items: flex-start
                .title
                    font-size: 2rem
                    display: flex
                    align-items: center
                    height: 100%
                .searchAndActions
                    display: flex
                    justify-content: flex-end
                    align-items: flex-start
                    width: 70%

                    .search
                        display: flex
                        flex-direction: column
                        margin-right: 1rem
                        margin-left: 1rem
                        height: 55px

                        .searchInputIcon
                            margin-right: 0.5rem
                            margin-left: 0.2rem
                            margin-bottom: 0.35rem
                    .export
                        margin-right: 1rem
                        margin-left: 1rem
                        height: 55px
                        .exportButtonIcon
                            margin-right: 0.5rem
                            margin-left: 0.2rem
                            margin-bottom: 0.35rem

        .selectedTab
            background: rgba(255, 163, 81, 0.04)
            box-shadow: inset 0px -1px 0px #FFA351
        .rank
            display: inline-block !important
            text-align: center
            font-size: 14px
            color: #FFF4EA
            padding: 5px 15px
            border-radius: 3px
            color: #FFF4EA
            width: 100px
            &.low
                background-color: #FFA351
            &.high
                background-color: #990F10
            &.medium
                background-color: #EE5253
            &.suspect
                background-color: #FFC845

.dropdownContent.content
    display: flex
    align-self: flex-end !important
    align-items: center
    background-color: #ffffff00 !important
    border-radius: 0px !important
    padding: 10px 20px
    & *
        margin-right: 10px
    .btn
        width: auto
        background: rgba(0, 0, 0, 0.0)
        outline: none
    .disabled
        cursor: not-allowed
    .img
        width: 32px
        height: 32px
.hasError
    border: solid 1px #f00 !important
    border-radius: 5px ,
