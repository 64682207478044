.pageWrapper
    width: 95%
    padding: 7rem 3rem 3rem 3rem
    min-height: 110vh
    .performanceWrapper
        width: 100%
        display: flex
        flex-direction: row
        padding: 56px 0
        .performanceTitle
            display: flex
            flex-direction: row
            align-items: center
            margin-bottom: 3rem
            margin-top: 4rem
            .icon
                width: 44px
                height: 44px
            .text
                font-size: 28px
                font-weight: bold
                margin: 0 12px
        .performanceTable
            display: flex !important
            justify-content: flex-end !important
            height: 664px
    .factsWrapper
        display: flex
        flex-direction: column
        padding: 4.9rem 0rem 1.6rem 0rem
        .factsContainer
            display: flex
            flex-direction: row
            .factsIconWrapper
                width: 44px
                height: 44px
                display: flex
                justify-content: center
                align-items: center
                border-radius: 4px
                background-color: #1c1c1e
                .factsIcon
                    width: 20px
                    height: 20px
            .factsLabel
                font-size: 2.4rem
                margin-bottom: 2.4rem
                font-weight: bold
                color: #000000
                justify-content: space-between
                margin: 0 12px
    .overAllProgressWrapper
        display: flex
        flex-direction: column
        justify-content: center
        width: 100%
        background: #FFFFFF
        border: 1px solid #E5E5EA
        box-sizing: border-box
        border-radius: 4px
