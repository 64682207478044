@import ../../../../sass/colors

.wrapper
  justify-content: space-between !important
  opacity: 1
  width: 100%
  display: flex
  margin: 0 0 1rem 0
  padding: 0 13rem
  border-radius: 5px
  height: 11rem
  align-items: center
  box-shadow: 0 1px 3px 0 rgba(26, 29, 12, 0.15)
  *
    margin: 0
  .userInfo,.phone
    flex: 2
    font-size: 1.6rem
    font-weight: 900
    color: #2d2d2d
  .userInfo
    flex: 1
    display: flex !important
    align-items: center !important
    .unassigned
      background: rgba(241, 168, 169,.5)
      color: #db3030
      font-size: 1.4rem
      font-weight: 500
      line-height: 1.29
      letter-spacing: .3px
      padding: .5rem 1rem
      text-transform: lowercase
      font-style: oblique
      border-radius: 1.55rem
    .initials
      width: 5.5rem
      height: 5.5rem
      border-radius: 50%
      background: #FDBB11
      display: flex !important
      align-items: center !important
      justify-content: center !important
      font-size: 1.6rem
      font-weight: 500
      line-height: 1.68
      letter-spacing: .4px
      color: white
    .name
      margin: 0 1rem !important
      display: flex !important
      align-items: center !important
      justify-content: center !important
  .plus
    font-size: 1.6rem
    direction: rtl
  .phone
    font-size: 1.6rem
    font-weight: 500
    flex: 1.7
    direction: ltr
    display: flex !important
    justify-content: center !important
  //.phone.ltr
  //  direction: ltr
  //  display: flex !important
  //  justify-content: normal !important
  .branch
    font-size: 1.6rem
    //direction: rtl
    display: flex !important
    justify-content: flex-start !important
    font-weight: 500
    color: #2d2d2d
    flex: 1.5
    align-items: flex-start !important
    .icon
      //direction: rtl
      //display: flex
      color: black
    .branchName
      //direction: ltr
      margin: 0 .7rem
  .btnWrapper
    flex: 1
    display: flex
    opacity: 0
    transition: all .2s
    .btn
      background: none
      margin: 0 1rem
      border: 0
      outline: none !important
      //box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1)
      display: flex
      width: 4.5rem
      height: 4.5rem
      border-radius: 50%
      align-items: center
      justify-content: center
      transition: all .2s
      .img
        width: 2rem
        height: 2rem
    .btn:hover,.btn:active
      //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1)
      background: #f6f6f6
.wrapper:hover
  .btnWrapper
    opacity: 1 !important
