.closeWrapper,.closeWrapperRtl
  position: absolute
  top: 2.5rem
  width: 2rem
  height: 2rem
  background: #e3e3e3
  border-radius: 50%
  display: flex
  align-items: center
  z-index: 2
  justify-content: center
.closeWrapper
  right: 1.5rem
.closeWrapperRtl
  left: 1.5rem
.close
  margin: 0
  width: 1rem
  height: 1rem
.wrongCode, .rightCode
  display: flex
  width: 67rem
  height: 7rem
  padding: 1rem 2rem
  align-items: center
  z-index: 4000
  box-shadow: 0 12px 24px 0 rgba(0, 23, 59, 0.1)
  background: white
  border-left: 4px solid #ff0606
  img
    width: 3rem
    height: 3rem
    margin: 0 .5rem
  .content
    margin: 0 2rem
    color: #2D2D2D
    font-weight: 500
    font-size: 1.8rem
.rightCode
  border-left: 4px solid #7ed321
.rtl
  direction: rtl !important
  .wrongCode, .rightCode
    border-left: 0
    border-right: 4px solid #ff0606
  .rightCode
    border-right: 4px solid #7ed321
  *
    direction: rtl !important
    text-align: right
