.wrapper
    width: 130px
    height: 40px
    border: 1px solid #8E8E93
    box-sizing: border-box
    background-color: #fff
    cursor: pointer
    border-radius: 4px
    font-size: 14px
    font-weight: bold
    position: relative
    .dropDownHeader
        height: 100%
        width: 100%
        display: flex
        flex-direction: row
        justify-content: center
        justify-items: center
        align-content: center
        align-items: center
        border-radius: 4px
        outline: none
        background-color: #fff
    .dropDownHeaderFocus
        background-color: #e9e9e9
        color: #000000
    .dropDownList
        display: flex
        flex-direction: column
        justify-content: center
        justify-items: center
        align-items: center
        align-content: center
        position: absolute
        margin-top: 3px
        right: 0px
        left: -20px
        background-color: #fff
        width: 152px
        box-shadow: 0 4px 8px 0 rgba(142, 142, 147, 0.12)
        border-radius: 4px
        z-index: 1
        .listItem
            width: 100%
            height: 45px
            display: flex
            flex-direction: column
            justify-content: center
            justify-items: flex-start
            align-items: flex-start
            align-content: center
            background-color: #fff
            color: #1C1C1E
            padding: 0px 8px
            outline: none
            font-size: 14px
        .listItem:hover
            background-color: #e9e9e9
    .globeIcon
        margin-right: 5px
    .upDownArrowIcon
        margin-left: 5px
.rtl
    .dropDownHeader
    .dropDownHeaderFocus
    .dropDownList
        right: -22px
        .listItem
    .globeIcon
        margin-left: 5px
    .upDownArrowIcon
        margin-right: 5px
