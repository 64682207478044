
.wrapper
    width: 130px
    height: 40px
    box-sizing: border-box
    background-color: #fff
    cursor: pointer
    border-radius: 4px
    font-size: 14px
    font-weight: bold
    .dropDownHeader
        height: 100%
        width: 100%
        display: flex
        flex-direction: row
        justify-content: center
        justify-items: center
        align-content: center
        align-items: center
        outline: none
        background-color: #fff
    .dropDownList
        display: flex
        flex-direction: column
        justify-items: flex-start
        align-items: flex-start
        justify-content: flex-start
        align-content: flex-start
        position: absolute
        margin-top: 3px
        right: auto
        background-color: #fff
        min-width: 266px
        box-shadow: 0 4px 8px 0 rgba(142, 142, 147, 0.12)
        border-radius: 4px
        z-index: 1
        .avatarContainer
            display: flex
            flex-direction: row
            padding: 12px 0px 8px 8px
            .storeNameAndUsernameContainer
                display: flex
                flex-direction: column
                .username
                    flex: none
                    order: 0
                    flex-grow: 0
                    margin: 2px 0px
                    font-size: 14px
                    line-height: 19px
                    color: #1C1C1E
                .storeName
                    font-size: 12px
                    line-height: 16px
                    color: #8E8E93
                    flex: none
                    order: 1
                    flex-grow: 0
                    margin: 2px 0px
        .separator
            width: 100%
            height: 1px
            background-color: #F2F2F7
        .listItem
            width: 100%
            height: 45px
            display: flex
            flex-direction: column
            justify-content: center
            justify-items: flex-start
            align-items: flex-start
            align-content: center
            background-color: #fff
            color: #1C1C1E
            padding: 0px 8px
            outline: none
            font-size: 14px
        .listItem:hover
            background-color: #e9e9e9
    .globeIcon
        margin-right: 5px
    .upDownArrowIcon
        margin-left: 5px
.rtl
    .dropDownList
        .avatarContainer
            padding: 12px 8px 8px 0
