@import ../../../../sass/colors

.wrapper
  width: 100%
  display: flex
  padding: 5rem 0
  flex: 1
  flex-direction: column
  align-items: flex-start
  .header
    font-size: 2.5rem
    color: #A8A8A8
  .subHeader
    font-weight: 900
    color: black
    font-size: 2.4rem
    margin: 1rem 0
  .row
    width: 100%
    justify-content: space-between
    align-items: center
    display: flex
    background: white
    box-shadow: 0 2px 10px 0 rgba(45, 45, 45, 0.11)
    padding: 3rem 2.7rem
    margin: 5rem 0 0 !important
    .fp
      display: flex
      flex: 1
      justify-content: space-between
      align-items: center
      .addedAndLabel
        display: flex
        flex-direction: column
        padding: 0 3rem
        .branchesAdded
          margin: 0
          color: black
          font-size: 2.2rem
        .labelsWrapper
          display: flex
          align-items: center
          margin: .5rem 0 0
          .assigned,.unassigned,.all
            display: flex
            align-items: center
            cursor: pointer
            justify-content: center
            border-radius: 5px
            background: white
            padding: .7rem 1.5rem
            border: solid 1px #ebebeb
            .color
              width: 1.5rem
              height: .7rem
              border-radius: 1rem
              margin: 0 .25rem
            .label
              margin: 0 .25rem
              font-size: 1.2rem
              line-height: 1
              color: #2d2d2d
          .assigned
            margin: 0 1rem
            .color
              background: #38c172
          .unassigned .color
            background: #db3030
          .active
            border: 1px solid #fdbb11
      .search
        display: flex
        align-items: center
        flex-direction: row-reverse
        border-radius: 5px
        box-shadow: inset rgba(0, 0, 0, 0.15)
        border: solid 1px #ebebeb
        background: #ffffff
        margin: 0 2.5rem
        height: 4.4rem
        .logo
          margin: 0 1rem
          height: 1.5rem
          width: 1.5rem
        .input
          outline: none
          color: black
          font-weight: 500
          border: none
          font-size: 2rem
          background: white
          border-radius: 5px
          padding: .5rem 1.5rem
        .input::placeholder
          color: #9b9b9b
        .input:disabled
          background: rgba($color-border,.1)
    .addBranch
      background: linear-gradient(#febe19 0%, #feb817 48.56%, #fead12 100%)
      display: flex
      align-items: center
      justify-content: space-between
      border: none !important
      box-shadow: none !important
      outline: 0 !important
      padding: 1.3rem 1.6rem
      color: white
      border-radius: 4rem
      transition: all .2s
      span
        margin: 0 .25rem
        font-size: 1.8rem
      .text
        margin: 0 .25rem
        font-size: 1.4rem
        font-weight: 700

    .addBranch:hover
      transform: translateY(-3px)
      box-shadow: $box-shadow-btn-hover !important
    .addBranch:active
      transform: translateY(-1px)
