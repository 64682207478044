.inputWithBadgeWrapper
  display: flex
  border-radius: 4px
  border: solid 1px #e3e3e3
  background-color: #ffffff
  color: #2d2d2d
  padding: 2rem
  font-size: 1.6rem
  .inputWrapper
    border: 0
    padding: 0
    &:focus
      outline: 0
