.main
  display: flex
  flex-direction: row
  align-items: center
  margin-bottom: 2.8rem
  .progressBarWrapper
    height: 1.6rem
    border-radius: 1.2rem
    background-color: #f6f6f7
    .progressBar
      height: 1.6rem
      background-color: #ffa351 !important
      border-radius: 1.2rem
      &:hover
        background-color: #f09440 !important
  .label
    width: 30%
    font-size: 1rem
    justify-content: center !important
    margin-right: 10px
    color: #1c1c1e
.wrapper
  display: flex
  flex-direction: column
  width: 100%
  .percentageMain
    display: flex
    flex-direction: column
    margin-left: 4.8rem
    .separator
      width: 100%
      height: .1rem
      margin-bottom: .7rem
      background-color: #f2f2f7
    .percentageWrapper
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 100%
      .percentage, .firstPercentage
        font-size: 1rem
        color: #8e8e93
.toolTipMain
  display: flex
  flex-direction: column
  padding: .8rem
  .tooltipContent
    display: flex
    flex-direction: row
    align-items: center
    margin-top: .8rem
    .tooltipGender
      font-size: 1.2rem
      color: #8e8e93
      margin-left: .8rem
    .iconWrapper
      width: 2rem
      height: 2rem
      flex-grow: 0
      display: flex
      justify-content: center
      align-items: center
      gap: 1rem
      padding: .4rem
      border-radius: 1.8rem
      background-color: #f2f2f7
      .icon
        width: 1.2rem
        height: 1.2rem
  .tooltipTitle
    font-size: 1.2rem
    color: #1c1c1e
    .price
      font-size: 1.2rem
      color: #1c1c1e
      margin-left: .4rem
      font-weight: bold
  &.rtl
    .tooltipTitle
      .price
        direction: rtl
    .tooltipContent
      direction: rtl
