.main
  .wrapper
    width: 100%
    .headerWrapper
      display: flex
      justify-content: space-between
      padding: 1.6rem 2.4rem
      .typeNameWrapper
        display: flex
        flex-direction: row
        align-items: center
        width: 50%
      .branchName , .typeName
        font-size: 1.4rem
        font-weight: 900
        letter-spacing: 0.7px
        color: #9b9b9b
        width: 100%
      .fTypeName
        margin: 0 5.8rem
      .typeName
        width: auto !important

    .searchWrapper
      display: flex
      flex-direction: row
      justify-content: space-between
      background-color: rgba(0, 0, 0, 0)
      border-radius: .5rem
      outline: none
      border: solid 1px #ebebeb
      align-items: center
      background-color: #ffffff
      margin: 2.4rem
      margin-bottom: .9rem
      padding: 0 1.5rem
      input
        border: 0
        border-top-left-radius: .5rem
        outline: none
        border-bottom-left-radius: .5rem
        height: 5.2rem
        flex: 3
        font-size: 1.6rem
      input::placeholder
        color: #9b9b9b
      input:focus::placeholder
        outline: none
        color: transparent

  .content
    overflow-y: auto
    overflow-x: hidden
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .branches
      width: 100%
      height: 49vh
    .notExistItem
      font-size: 2.6rem
      font-weight: 500
      color: #222222
  .btnWrapper
    width: 77rem
    border-radius: 0 0 1rem 1rem
    border: solid 1px #979797
    background-color: #f2f2f2
    display: flex
    justify-content: space-between
    padding: 2.4rem 3.2rem
    flex-direction: row
    .countWrapper
      display: flex
      justify-content: center
      flex-direction: column
      .count
        font-size: 2.8rem
        font-weight: 700
        color: #000000
      .branchSelected
        font-size: 1.6rem
        color: #000000
    .buttonsWrapper
      display: flex
      flex-direction: row
      align-items: center

      .btn
        width: 14rem
        height: 5.6rem
        border-radius: .5rem
        font-size: 1.6rem
        font-weight: bold
        letter-spacing: 0.11px
        text-align: center
        outline: none
        color: #ffffff

      .activBtn
        background-color: #f09440 !important
      .unactivBtn
        background-color: #a6a6a6 !important
        cursor: not-allowed !important

      .disableBtn, .disabled
        width: 14rem
        height: 5.6rem
        border-radius: .5rem
        font-size: 1.6rem
        font-weight: bold
        letter-spacing: 0.11px
        text-align: center
        outline: none
        color: #ffffff
        background-color: #db3030 !important
        margin: 0 8px
        &:hover, &:active
          background-color: #db3030 !important
      .disabled
        background-color: #a6a6a6 !important
        cursor: not-allowed !important
        &:hover, &:active
          background-color: #a6a6a6 !important
  &.rtl
    .btnWrapper
      display: flex
      flex-direction: row-reverse
