$bg-largest: 85.625em //1370
$bg-largest-2: 80em //1280
$bg-large: 68.75em //1100
$bg-medium-1: 56.25em //900
$bg-medium-2: 46.875em //750
$bg-small: 37.5em //600
$bg-smallest-1: 31.25em //500
$bg-smallest-2: 25em //400
$bg-smallest-3: 20em //321

*
  margin: 0
  padding: 0
  button
    cursor: pointer

*,
*::after,
*::before
  box-sizing: inherit
  cursor: inherit

html
  box-sizing: border-box
  font-size: 62.5%
  @media only screen and (max-width: $bg-largest)
    font-size: 58%
  @media only screen and (max-width: $bg-largest-2)
    font-size: 52%
  @media only screen and (max-width: $bg-large)
    font-size: 45%
  @media only screen and (max-width: $bg-medium-1)
    font-size: 40%
  @media only screen and (max-width: $bg-medium-2)
    font-size: 30%
  @media only screen and (max-width: $bg-small)
    font-size: 25%
  @media only screen and (max-width: $bg-smallest-2)
    font-size: 15%
