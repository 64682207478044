@import "../../../../sass/colors"

.wrapper
  width: 230px
  display: flex
  flex-direction: column
  align-items: center
  .text
    font-size: 1.6rem
    font-weight: 500
    margin: 3rem 0
    text-align: center
.mobile
  width: 230px
  height: 463px
  padding: 10px
  background: #2D2D2D
  border-radius: 30px
  position: relative
  .brandWrapper
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    position: absolute
    top: 40px
    left: 0
    z-index: 1
    margin: 0
    padding: 0
    .brandName
      font-size: 18px
      font-weight: 700
      color: white
      margin: 1px 0
    .logo
      object-fit: cover
      margin: 0
      width: 62px
      border-radius: 50%
      height: 62px
  .bg
    width: 212px
    left: 9px
    top: 141px
    position: absolute
    height: 311px
    border-radius: 0 0 20px 20px
    display: flex
    padding: 0
    justify-content: center
    .map
      width: 100%
      height: 100%
      border-radius: 0 0 20px 20px
  .cover
    position: absolute
    left: 9px
    border-radius: 20px 20px 0 0
    top: 9px
    z-index: 1
    background: #6c6c6c
    width: 212px
    height: 132px
  .notch
    background: #2D2D2D
    position: absolute
    top: 0
    left: 25%
    width: 50%
    height: 20px
    z-index: 2
    border-radius: 0 0 15px 15px
