.main, .rtl
    display: flex
    justify-content: center
    align-items: center
    .wrapper
        display: flex
        flex-direction: column
        width: 568px
        border-radius: 10px
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13)
        background-color: #fff
        .buttonsWrapper
            width: 100%
            padding: 16px 24px 16px 224px
            border-radius: 0 0 10px 10px
            background-color: #f2f2f2
            display: flex
            flex-direction: row
            justify-content: flex-end
            align-items: center
            .cancel, .confirm
                width: 168px
                max-width: 100%
                display: flex
                justify-content: center
                align-items: center
                margin: 0 8px 0 0
                padding: 15px 49.5px 15px 50.5px
                border-radius: 5px
                border: solid 1px #ee8728
                font-size: 14px
                font-weight: bold
                outline: none
                color: #ee8728
            .confirm
                margin: 0 0 0 8px
                border: solid 1px #db3030
                border-radius: 5px
                background-color: #db3030
                font-size: 14px
                font-weight: bold
                color: #fff

        .title
            padding: 32px 95px 72px 32px
            font-size: 22px
            font-weight: 500
            color: #2d2d2d
        .description
            font-size: 16px
            font-weight: 500
            display: flex !important
            justify-content: center !important
            color: #2d2d2d
            padding-bottom: 72px
        .imageWrapper
            display: flex
            justify-content: center
            width: 100%
            .warningImage
                width: 56px
                height: 56px
                margin: 0 133px 24px
.rtl
    direction: rtl
    .wrapper
        .buttonsWrapper
            padding: 16px 224px 16px 24px
            .cancel
                margin: 0 0 0 8px
        .title
            padding: 32px 32px 72px 95px
