.main
    display: flex
    flex-direction: column
    width: 100%
    margin-bottom: 23px
    .analyzeCustomers
        border-bottom: solid 0.1rem #e5e5ea
        border-right: solid .1rem #e5e5ea
        border-left: solid .1rem #e5e5ea
        cursor: pointer
        display: flex
        flex-direction: row
        justify-content: flex-end
        width: 100%
        align-items: center
        text-decoration: none

        .analyzeCustomersWrapper
            display: flex
            flex-direction: row
            align-items: center
            padding: 1rem 1.2rem
            height: 100%
            .iconWrapper
                width: 1.6rem
                height: 1.6rem
                transform: rotate(270deg)
            .analyzeCustomersTitle
                font-size: 1.4rem
                font-weight: bold
                color: #ffa351
            &:hover
                background-color: #f2f2f7
    .collapseWrapper
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-end
        gap: 2.4rem
        padding: 3.7rem 2.4rem 2.4rem 2.4rem
        border-radius: 4px
        border: solid 1px #e5e5ea
        border-top: 0
        box-shadow: inset 0 -1px 0 0 #f2f2f7
        background-color: #ffffff
        .timeWrapper
            width: 100%
            display: flex
            justify-content: flex-end
            height: 3.2rem
            margin-bottom: 2.4rem

    .wrapper
        width: 100%
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        gap: 2.4rem
        margin-bottom: 0
        border-radius: 4px
        border: solid 1px #e5e5ea
        padding: 1.25rem 1.5rem
        background-color: #ffffff
        .placeholderWrapper
            width: 100%
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
            .placeholderIcon
                width: 8.7rem
                height: 8.7rem
                margin-bottom: 2.5rem
                margin-top: 3.3rem
            .placeholderTitle

                padding: 1.6rem
                font-size: 1.6rem
                color: #000000
                padding: 0 9.6rem

                text-align: center
        .customerWrapper
            display: flex
            align-items: center
            flex-direction: row
            .malesFrom
                font-size: 1.6rem
                color: #000000
            .avatarWrapper
                width: 5.6rem
                height: 5.6rem
                flex-grow: 0
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                gap: 1rem
                margin: 0 1.6rem 0 0
                border-radius: .4rem
                background-color: #f2f2f7
        .headWrapper
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            .seeMoreButton
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                padding: 0.625rem 0.75rem
                background-color: white
                border: none
                font-weight: bold
                height: 40px
                &:hover
                    background-color: #f2f2f7
                    transition: 0.2s
                    border: none
                &:focus
                    outline: none
                span
                    padding-left: 0.3125rem
            .titleWrapper
                display: flex
                flex-direction: column
                .title
                    font-size: 2.4rem
                    font-weight: bold
                    color: #000000
                .subtitle
                    font-size: 1.6rem
                    color: #000000
.rtl
    .analyzeCustomers
        .analyzeCustomersWrapper
            .iconWrapper
                transform: rotate(90deg)
    .wrapper
        .customerWrapper
            .avatarWrapper
                margin: 0 0 0 1.6rem
        .headWrapper

            .seeMoreButton
                span
                    padding-left: 0
                    padding-right: 5px !important
