.wrapper
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%
    background: #FFFFFF
    border: 1px solid #E5E5EA
    box-sizing: border-box
    border-radius: 4px
    .selectedTab
        background: rgba(255, 163, 81, 0.04)
        box-shadow: inset 0px -1px 0px #FFA351
.tabs
    display: flex
    justify-content: center
    width: 100%
    .chartWrapper
        padding: 0 24px 21px 24px
        .numbersAndFiltersContainer
            display: flex
            justify-content: space-between

.tabWrapper
    display: flex
    flex-direction: row
    width: 100%
    justify-content: center
    .iconWrapper,.rotateIconWrapper
        display: flex
        align-items: center
        margin: 0 .8rem
        width: 1.2rem
    .rotateIconWrapper
        transform: rotate( 180deg )
