.wrapper
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    margin-top: 2.4rem
    .ordersCard,
    .feedbackCard,
    .adsManagerCard,
    .insightsCard
        text-decoration: none
        display: flex
        flex-direction: row
        border: solid 1px #e5e5ea !important
        align-items: center
        width: 100%
        cursor: pointer
        border-radius: 4px 0 0 4px
        height: 80px
        border-right: 0
        padding: 19px 12px
        box-shadow: inset -1px 0 0 0 #f2f2f7
        background-color: #ffffff
        justify-content: space-between

        .card
            display: flex
            flex-direction: row
            align-items: center
            .arrowRight
                width: 2.4rem
                height: 2.4rem
            .iconWrapper
                width: 3.2rem
                height: 3.2rem
                display: flex
                justify-content: center
                align-items: center
                padding: .8rem
                border-radius: 8rem
                background-color: #f2f2f7
                margin-right: 1.2rem
                .icon
                    width: 1.6rem
                    height: 1.6rem
            .titleWrapper
                display: flex
                flex-direction: column
                .feedbackWrapper
                    display: flex
                    flex-direction: row
                    align-items: center
                    .feedbackTitle
                        margin-right: .8rem
                        font-size: 1.6rem
                        font-weight: bold
                        color: #1c1c1e
                    .feedback
                        display: flex
                        justify-content: center
                        align-items: center
                        padding: 4px 12px
                        border-radius: 130px
                        border: solid 1px #ffa351
                        background-color: #ffa351
                        .number

                            font-size: 1.2rem
                            color: #ffffff
                .title
                    font-size: 1.6rem
                    font-weight: bold
                    color: #1c1c1e
                .subtitle
                    font-size: 1.2rem
                    color: #8e8e93
        &:hover
            box-shadow: inset 0 2px 2px -1px rgba(28, 28, 30, 0.1)
            background-color: #f9f9fb
            transition: all .4s
    .insightsCard
        border-radius: 4px
    .feedbackCard
        border-right: 0
        border-radius: 0
        border-left: 0 !important
    .adsManagerCard
        text-decoration: none
        border-left: 0 !important
        border-radius: 0 4px 4px 0
        margin-right: 2.4rem

    .insights
.rtl
    .adsManagerCard,
    .feedbackCard,
    .ordersCard,
    .insightsCard
        .arrowRight
            transform: rotate(180deg) !important
        .card

            .iconWrapper
                margin-left: 1.2rem
                margin-right: 0
        .titleWrapper
            .feedbackTitle
                margin-left: .8rem
                margin-right: 0

    .adsManagerCard
        margin-right: 0
        margin-left: 2.4rem
        border-left: solid 1px #e5e5ea !important
        border-radius: 4px 0 0 4px

    .feedbackCard
        border-right: 0 !important
    .ordersCard
        border-left: 0 !important
        border-radius: 0 4px 4px 0
        .card
            .iconWrapper
    .insightsCard
        border-radius: 4px !important
