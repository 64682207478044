.main
    display: flex
    align-items: center
    justify-content: center
    .wrapper
        display: flex
        flex-direction: column
        align-items: center
        background-color: #fff
        width: 456px
        border-radius: 5px
        .footer
            width: 100%
            height: 80px
            display: flex
            flex-direction: row
            justify-content: flex-end
            align-items: center
            padding: 16px 24px
            box-shadow: inset 0 1px 0 0 #f2f2f7
            background-color: #fff
            border-radius: 0 0 5px 5px
            .deleteBtn
                width: 99px
                height: 48px
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                border-radius: 4px
                background-color: #ee5253
                font-size: 16px
                font-weight: bold
                color: #fff
            .cancelBtn
                width: 98px
                height: 48px
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                border-radius: 4px
                background-color: #f2f2f7
                font-size: 16px
                font-weight: bold
                margin: 0 12px
                color: #1c1c1e

        .deleteOperationTitle
            font-size: 20px
            font-weight: bold
            color: #1c1c1e
        .deleteOperationDescription
            font-size: 16px
            color: #1c1c1e
            margin-top: 4px
            margin-bottom: 24px
        .warningIcon
            width: 80px
            height: 80px
            margin-bottom: 24px
        .closeIcon
            width: 100%
            cursor: pointer
            padding: 24px
            display: flex
            align-items: center
            justify-content: flex-end
