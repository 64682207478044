.wrapper
    background-color: #fff
    width: 300px
    height: 640px
    border-radius: 27.87px
    border: 5px solid #000
    position: relative
    z-index: 12
    .tab
        position: absolute
        bottom: 0
        border-radius: inherit
    .bannerCardsWrapper
        background: var(--Java-Primary, #CF152D)
        width: 100%
        height: 125.952px
        position: relative
        .bannerCardPlaceholder
            width: 263.424px
            height: 146px
            border-radius: 9.216px
            background: #F2F2F7
            position: absolute
            bottom: -33px
            left: 0
            right: 0
            margin: 0 auto
            z-index: 12px
            max-height: 146.02px !important
            .bannerCard
                width: 100%
                height: 146px
                border-radius: 9.216px
                .bannerCardImage
                    width: 100%
                    height: 100%
                    object-fit: cover
                    border-radius: 8px

            .discount
                width: 100%
                height: 48px
                border-radius: 0px 0px 9.216px 9.216px
                border: 0.768px solid var(--Grey-grey_6, #F2F2F7)
                background: #FFF
                padding: 15.36px 12.288px
                position: absolute
                bottom: 0
                color: var(--Grey-grey_k, #1C1C1C)
                font-size: 11.52px
                font-weight: 400
    .drinkCards
        display: flex
        flex-direction: row
        align-items: center
        gap: 18.432px
        padding: 25px 12.29px
    .drinkName
        color: var(--Grey-grey_k, #1C1C1C)
        font-size: 13.056px
        font-weight: 600
        padding: 0 12.29px
    .drinks
        display: flex
        flex-direction: row
        align-items: center
        gap: 9.216px
        padding: 25px 12.29px
    .welcome
        display: flex
        flex-direction: row
        align-items: center
        gap: 6.144px
        padding: 18.43px 12.29px
        .welcomeText
            color: var(--Grey-grey_k, #1C1C1C)
            font-size: 15.36px
            font-weight: 400
            font-family: Lora

            .userName
                color: var(--Grey-grey_k, #1C1C1C)
                font-family: Lora
                font-size: 15.36px
                font-style: italic
                font-weight: 600
    .headerWrapper
        width: 100%
        height: 50px
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        padding: 16px 24px
        border-bottom: 1px solid #F2F2F7

        .storeInfoWrapper
            display: flex
            flex-direction: row
            align-items: center
            gap: 6.144px
            .storeInfo
                display: flex
                flex-direction: row
                gap: 6.144px
                .branchName
                    color: var(--Grey-grey_k, #1C1C1C)
                    font-size: 9.984px
                    font-weight: 400
            .deliveryPickupIcon
                width: 18.43px
                height: 18.43px

        .storeLogo
            width: 40px
            height: 40px
            object-fit: contain
            border-radius: 8px
    .statusBar
        border-radius: inherit
        height: 40px
        display: flex
        align-items: center
        flex-direction: row
        justify-content: space-between
        background-color: #fff

    .notch
        display: flex
        justify-content: center
        align-items: center
        width: 168.32px
        height: 21.95px
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%)
        background: #000
        border-radius: 0 0 15px 15px
        .camera
            width: 5.55px
            height: 5.55px
            border-radius: 8.62px
            background-color: #2E2E2E
            margin-right: 9.23px
        .speaker
            width: 36.65px
            height: 5.24px
            border-radius: 8.62px
            background-color: #2E2E2E
    &.rtl
        direction: ltr
        .tab
            left: 0
.customCarousel
    width: 263.424px
    height: 146px
    overflow: hidden
    border-radius: 9.216px
.slick-dots
    position: absolute
    bottom: 10px // Adjust this value as needed
    width: 100%
    display: flex !important
    justify-content: center
    align-items: center

    li
        list-style-type: none // This line removes the list item markers
        margin: 0 5px // Adjust spacing between dots as needed

        button
            font-size: 0
            line-height: 0
            display: block
            width: 10px // Adjust dot size as needed
            height: 10px // Adjust dot size as needed
            padding: 5px // Adjust padding as needed
            cursor: pointer
            color: transparent
            border: 0
            outline: none
            background: #c1c1c1 // Adjust dot color as needed
            border-radius: 50%

        &:hover
            background: darken(#c1c1c1, 10%) // Adjust hover color as needed

        &:active
            background: darken(#c1c1c1, 20%) // Adjust active color as needed

    li
        .slick-active
            button
                background: #333 !important // Adjust active dot color as needed, using !important to ensure it's applied
